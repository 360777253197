/**
 * Feature flags
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */

export const featureFlags = {
	useMockService: import.meta.env.VITE_USE_MOCK_SERVICES === 'true',
};

/**
 * Firmware service
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import * as E from 'fp-ts/lib/Either';
import * as TE from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
import StatusCodes from 'http-status-codes';

import {
	FirmwareCreationData,
	FirmwareData,
	FirmwaresResponse,
} from '@thingos/firmware-configurator-shared';

import { apiService } from './apiService';
import { exampleQSeriesFw } from './mockData';
import { FirmwareJson } from './types';
import { uuidv4 } from './utils';

export type GetFirmwaresFailure = 'UnknownError' | 'ServerError' | 'Redirect';
function getFirmwares(): TE.TaskEither<GetFirmwaresFailure, FirmwareData[]> {
	return pipe(
		TE.tryCatch<GetFirmwaresFailure, Response>(
			async () =>
				fetch(apiService.api + '/firmwares', {
					method: 'GET',
					credentials: 'include',
					mode: 'cors',
				}),
			() => 'UnknownError'
		),
		TE.chain(response => {
			if (response.status === StatusCodes.OK) {
				return pipe(
					TE.tryCatch(
						async () => response.json(),
						() => 'Could not get json body of response'
					),
					TE.chain(body =>
						pipe(
							FirmwaresResponse.decode(body),
							E.mapLeft(() => 'Could not decode response'),
							TE.fromEither
						)
					),
					TE.mapLeft(error => {
						console.error(error);
						return 'UnknownError';
					})
				);
			} else if (response.status === StatusCodes.MOVED_TEMPORARILY) {
				return TE.left('Redirect');
			} else {
				return TE.left('ServerError');
			}
		})
	);
}

type UpdateFirmwareFailure = 'UnknownError' | 'ServerError';
function updateFirmware(
	firmwareData: FirmwareData
): TE.TaskEither<UpdateFirmwareFailure, FirmwareData> {
	return pipe(
		TE.tryCatch<UpdateFirmwareFailure, Response>(
			async () =>
				fetch(apiService.api + '/firmware', {
					method: 'POST',
					credentials: 'include',
					mode: 'cors',
					headers: new Headers({
						'Content-Type': 'application/json',
					}),
					body: JSON.stringify(firmwareData),
				}),
			() => 'UnknownError'
		),
		TE.chain(response => {
			if (response.status === StatusCodes.OK) {
				return pipe(
					TE.tryCatch(
						async () => response.json(),
						() => 'Could not get json body of response'
					),
					TE.chain(body =>
						pipe(
							FirmwareData.decode(body),
							E.mapLeft(() => 'Could not decode response'),
							TE.fromEither
						)
					),
					TE.mapLeft(error => {
						console.error(error);
						return 'UnknownError';
					})
				);
			} else {
				return TE.left('ServerError');
			}
		})
	);
}

type PostFirmwareFailure = 'UnknownError' | 'ServerError';
function postFirmware(
	firmwareCreationData: FirmwareCreationData
): TE.TaskEither<PostFirmwareFailure, FirmwareData> {
	return pipe(
		TE.tryCatch<PostFirmwareFailure, Response>(
			async () =>
				fetch(apiService.api + '/firmwares', {
					method: 'POST',
					credentials: 'include',
					mode: 'cors',
					headers: new Headers({
						'Content-Type': 'application/json',
					}),
					body: JSON.stringify(firmwareCreationData),
				}),
			() => 'UnknownError'
		),
		TE.chain(response => {
			if (response.status === StatusCodes.OK) {
				return pipe(
					TE.tryCatch(
						async () => response.json(),
						() => 'Could not get json body of response'
					),
					TE.chain(body =>
						pipe(
							FirmwareData.decode(body),
							E.mapLeft(() => 'Could not decode response'),
							TE.fromEither
						)
					),
					TE.mapLeft(error => {
						console.error(error);
						return 'UnknownError';
					})
				);
			} else {
				return TE.left('ServerError');
			}
		})
	);
}

type DownloadFirmwareFailure = 'UnknownError' | 'ServerError';
function downloadFirmware(firmwareId: string): TE.TaskEither<DownloadFirmwareFailure, ArrayBuffer> {
	return pipe(
		TE.tryCatch<DownloadFirmwareFailure, Response>(
			async () =>
				fetch(apiService.api + `/firmware/hex/${firmwareId}`, {
					method: 'GET',
					credentials: 'include',
					mode: 'cors',
				}),
			() => 'UnknownError'
		),
		TE.chain(response => {
			if (response.status === StatusCodes.OK) {
				return pipe(
					TE.tryCatch(
						async () => response.arrayBuffer(),
						() => 'Could not get array buffer of response'
					),
					TE.mapLeft(error => {
						console.error(error);
						return 'UnknownError';
					})
				);
			} else {
				return TE.left('ServerError');
			}
		})
	);
}

type DeleteFirmwareFailure = 'UnknownError' | 'ServerError';
function deleteFirmware(firmwareId: string): TE.TaskEither<DeleteFirmwareFailure, true> {
	return pipe(
		TE.tryCatch<DownloadFirmwareFailure, Response>(
			async () =>
				fetch(apiService.api + `/firmware/${firmwareId}`, {
					method: 'DELETE',
					credentials: 'include',
					mode: 'cors',
				}),
			() => 'UnknownError'
		),
		TE.chain(response => {
			if (response.status === StatusCodes.OK) {
				return TE.right(true);
			} else {
				return TE.left('ServerError');
			}
		})
	);
}

export const firmwareService = {
	getFirmwares,
	postFirmware,
	updateFirmware,
	downloadFirmware,
	deleteFirmware,
};

export type FirmwareService = typeof firmwareService;

const mockFirmwares: FirmwareJson[] = [
	{
		id: 'f56254f5-bea5-42d5-8149-f51b2fc69e6f', // Unique id
		version: '1.1', // Semantic version
		name: 'My second Firmware', // Name of the firmware
		resourceId: 'light-2', // User generated id
		config: JSON.stringify(exampleQSeriesFw), // Firmware configuration
		icon: 'devices/bulb', // User selec
		type: 'mono',
	},
	...Array(20)
		.fill(0)
		.map(
			() =>
				({
					id: uuidv4(), // Unique id
					version: '1.0', // Semantic version
					name: 'My awesome Firmware', // Name of the firmware
					resourceId: 'light-1', // User generated id
					config: JSON.stringify(exampleQSeriesFw), // Firmware configuration
					icon: 'devices/bulb', // User selec
					type: 'mono',
				} as FirmwareJson)
		),
];

export const firmwareServiceMock: FirmwareService = {
	getFirmwares() {
		return pipe(
			TE.tryCatch(
				async () =>
					new Promise(resolve => setTimeout(resolve, Math.random() * 2000)).then(
						() => mockFirmwares as FirmwareData[]
					),
				() => 'UnknownError' as GetFirmwaresFailure
			)
		);
	},
	postFirmware(firmwareCreationData: FirmwareCreationData) {
		return pipe(
			TE.tryCatch(
				async () =>
					new Promise(resolve => setTimeout(resolve, Math.random() * 500)).then(
						() => ({ ...firmwareCreationData, id: uuidv4() } as FirmwareData)
					),
				() => 'UnknownError' as PostFirmwareFailure
			)
		);
	},
	updateFirmware(firmwareData: FirmwareData) {
		return pipe(
			TE.tryCatch(
				async () =>
					new Promise(resolve => setTimeout(resolve, Math.random() * 500)).then(() => firmwareData),
				() => 'UnknownError' as PostFirmwareFailure
			)
		);
	},
	downloadFirmware(firmwareId: string) {
		return pipe(
			TE.tryCatch(
				async () =>
					new Promise(resolve => setTimeout(resolve, Math.random() * 1000)).then(
						() => new ArrayBuffer(firmwareId.length)
					),
				() => 'UnknownError' as DownloadFirmwareFailure
			)
		);
	},
	deleteFirmware(_firmwareId: string) {
		return pipe(
			TE.tryCatch(
				async () =>
					new Promise(resolve => setTimeout(resolve, Math.random() * 1000)).then(() => true),
				() => 'UnknownError' as DownloadFirmwareFailure
			)
		);
	},
};

/**
 * Nav Sidebar Component
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Sidebar, useSnackbar } from '@thingos/thingos-components';

import { privatePaths } from '../pages/private';
import { publicPaths } from '../pages/public/public';
import { useStores } from '../stores';

export const SidebarContent: React.FunctionComponent = observer(() => {
	const { loginStore } = useStores();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	// after logout goto login page
	const onClickLogout = React.useCallback(() => {
		if (loginStore.isProcessing) return;
		loginStore.logout().then(isLoggedOut => {
			if (isLoggedOut) {
				navigate(publicPaths.login);
			} else {
				enqueueSnackbar('Could not log out, please reload page', { variant: 'error' });
			}
		});
	}, [navigate, loginStore, enqueueSnackbar]);

	return (
		<Sidebar.Container>
			<Sidebar.Top>
				<Sidebar.Link to={privatePaths.firmwares} icon="ui/rule" label="Firmwares" />
				{/* <Sidebar.Link to={privatePaths.users} icon="ui/structure" label="Benutzer" /> */}
			</Sidebar.Top>
			<Sidebar.Bottom>
				{/* <Sidebar.Avatar name="Admin" description="admin@thingos.io" /> */}
				<Sidebar.Button onClick={onClickLogout} icon="ui/logout" label="Logout" />
			</Sidebar.Bottom>
		</Sidebar.Container>
	);
});

/**
 * Firmware config store
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import json5 from 'json5';
import { action, computed, makeObservable, observable } from 'mobx';
import { Edge, Node, NodeChange, applyNodeChanges } from 'react-flow-renderer';

import { WebIconType } from '@thingos/design-icons';
import { FirmwareData, FirmwareType } from '@thingos/firmware-configurator-shared';
import { TableHeaderSortDirection } from '@thingos/thingos-components';

import { FirmwareService } from '../services/firmwareService';
import { TemplateService } from '../services/templateService';
import {
	FirmwareConfiguration,
	FirmwareConfigurationModuleProperty,
	FirmwareJson,
} from '../services/types';
import { Result, error, isOk, ok, snakeCase } from '../services/utils';
import type { LoginStore } from './loginStore';

interface FirmwareStoreContext {
	firmwareService: FirmwareService;
	templateService: TemplateService;
	loginStore: LoginStore;
}
export class FirmwareStore {
	public firmwares = observable.map<string, Firmware>();
	public isLoading = false;
	public ctx: FirmwareStoreContext;
	public sortByName: TableHeaderSortDirection | null = null;
	public firmwareSelection: FirmwareSelection | null = null;

	public get firmwareData(): Firmware[] {
		const order = this.sortByName === 'asc' ? 1 : -1;
		return Array.from(this.firmwares.values()).sort((a, b) => order * a.name.localeCompare(b.name));
	}

	constructor(ctx: FirmwareStoreContext) {
		this.ctx = ctx;
		makeObservable(this, {
			isLoading: observable,
			firmwares: observable,
			firmwareSelection: observable,
			sortByName: observable,

			firmwareData: computed,

			setLoading: action,
			setSortByName: action,
			setFirmwareSelection: action,
			addFirmware: action,
			setFirmwareList: action,
		});
	}

	public setLoading(isLoading: boolean): void {
		console.log('setLoading', isLoading);
		this.isLoading = isLoading;
	}

	public setSortByName(sortByName: TableHeaderSortDirection): void {
		this.sortByName = sortByName;
	}

	public setFirmwareSelection(firmware: Firmware): void {
		console.log('setFirmwareSelection', firmware);
		this.firmwareSelection = new FirmwareSelection(this, firmware);
	}

	public addFirmware(firmware: Firmware): void {
		console.log('add firmware', firmware);
		this.firmwares.set(firmware.id!, firmware);
	}

	public setFirmwareList(firmwares: FirmwareJson[]): void {
		this.firmwares.replace(
			firmwares.reduce(
				(map, f) => map.set(f.id!, new Firmware(this, f)),
				new Map<string, Firmware>()
			)
		);
	}

	public async loadFirmwares(): Promise<string> {
		this.setLoading(true);
		const result = await this.ctx.firmwareService.getFirmwares()();
		if (isOk(result)) {
			const fws = result.right as FirmwareJson[];
			this.setFirmwareList(fws);
			this.setLoading(false);
			return 'ok';
		}
		this.setLoading(false);
		return 'login';
	}

	public createFirmware(): void {
		this.setFirmwareSelection(
			new Firmware(this, {
				config: '',
				icon: 'devices/bulb',
				id: null,
				name: '',
				resourceId: '',
				version: '1.0',
				type: 'mw',
			})
		);
	}

	public async uploadFirmware(firmware: Firmware): Promise<FirmwareJson | null> {
		const result = await (firmware.id != null
			? this.ctx.firmwareService.updateFirmware(firmware.asJson() as FirmwareData)()
			: this.ctx.firmwareService.postFirmware(firmware.asJson())());
		if (isOk(result)) {
			this.addFirmware(new Firmware(this, result.right as FirmwareJson));
			return result.right as FirmwareJson;
		} else {
			return null;
		}
	}

	// loads firmware selection, if null, it will create a new one
	public async loadSelection(firmwareId: string | null): Promise<void> {
		if (firmwareId == null || firmwareId === 'new') {
			this.createFirmware();
		} else {
			const fw = this.firmwares.get(firmwareId);
			if (fw) {
				this.setFirmwareSelection(fw);
			} else {
				// const fw = await this.ctx.firmwareService.getFirmware(firmwareId);
				// this.setFirmwareSelection(new Firmware(this, fw));
			}
		}
	}

	public async deleteFirmware(firmwareId: string): Promise<void> {
		const result = await this.ctx.firmwareService.deleteFirmware(firmwareId)();
		if (isOk(result)) {
			this.firmwares.delete(firmwareId);
			return;
		} else {
			throw Error('Could not download firmware.');
		}
	}

	public async downloadFirmware(firmwareId: string, fileName = 'fw.hex'): Promise<void> {
		const result = await this.ctx.firmwareService.downloadFirmware(firmwareId)();
		if (isOk(result)) {
			const fw = result.right;
			const blob = new Blob([fw], { type: 'application/octet-binary' });
			const url = URL.createObjectURL(blob);
			const element = document.createElement('a');
			element.href = url;
			element.setAttribute('download', fileName);
			element.style.display = 'none';
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
		} else {
			throw Error('Could not download firmware.');
		}
	}
}

export class Firmware {
	public id: string | null;
	public name: string;
	public resourceId: string;
	public icon: WebIconType;
	public version: string;
	public firmwareStore: FirmwareStore;
	public config: string;
	public type: FirmwareType;

	constructor(firmwareStore: FirmwareStore, json: FirmwareJson) {
		console.log('create firmware', json);
		this.firmwareStore = firmwareStore;
		this.id = json.id;
		this.name = json.name;
		this.resourceId = json.resourceId;
		this.icon = json.icon;
		try {
			this.version = JSON.parse(json.config).version;
		} catch {
			this.version = json.version;
		}
		this.config = json.config ?? '';
		this.type = json.type ?? '';

		makeObservable(this, {
			id: observable,
			name: observable,
			resourceId: observable,
			icon: observable,
			config: observable,

			setName: action,
		});
	}

	public asJson(): FirmwareJson {
		return {
			id: this.id,
			name: this.name,
			resourceId: this.resourceId,
			icon: this.icon,
			version: this.version,
			config: this.config,
			type: this.type,
		};
	}

	public setName(name: string): void {
		this.name = name;
	}

	public setIcon(icon: WebIconType): void {
		console.log('setIcon', icon);
		this.icon = icon;
	}

	public setConfig(config: string): void {
		console.log('set config', config.slice(0, 40));
		this.config = config;
	}

	public async downloadFirmware(): Promise<void> {
		if (this.id == null) return Promise.resolve();
		return await this.firmwareStore.downloadFirmware(this.id, `${snakeCase(this.name)}.hex`);
	}
}

/**
 * Firmware selection is the ui store for the configuration edit
 * page. It will load the firmware using the url.
 *
 * @export
 * @class FirmwareSelection
 */
export class FirmwareSelection {
	public firmware: Firmware;
	public workingCopy: Firmware;
	public firmwareStore: FirmwareStore;
	public template: string;
	public isLoading = false;
	public isValid = false;
	public isGraphMode = false;

	public nodes = observable.array<Node>([], { deep: false });
	public edges = observable.array<Edge>([], { deep: false });

	constructor(firmwareStore: FirmwareStore, firmware: Firmware) {
		console.log('create selection', firmware);
		this.firmwareStore = firmwareStore;
		this.firmware = firmware;
		this.workingCopy = new Firmware(firmwareStore, firmware.asJson());
		if (firmware.type === 'd2w') {
			this.template = 'D2W';
		} else if (firmware.type === 'mono') {
			this.template = 'Mono';
		} else {
			this.template = 'MW';
		}
		if (this.workingCopy.config.length === 0) {
			this.loadTemplate(this.template);
		}

		this.onClickToggleGraphMode = this.onClickToggleGraphMode.bind(this);
		this.onHandleNodeChanges = this.onHandleNodeChanges.bind(this);

		makeObservable(this, {
			workingCopy: observable,
			isLoading: observable,
			template: observable,
			isValid: observable,
			isGraphMode: observable,
			nodes: observable.shallow,
			edges: observable.shallow,

			setLoading: action,
			setValid: action,
			loadTemplate: action,
			onHandleNodeChanges: action,
		});
	}

	public onClickToggleGraphMode(): void {
		this.setGraphMode(!this.isGraphMode);
	}

	public onHandleNodeChanges(changes: NodeChange[]): void {
		// TODO: check if auto update will throw away custom data
		// otherwise do it manually
		const newNodes = applyNodeChanges(changes, this.nodes.slice());
		this.nodes.replace(newNodes);
		// for (const change of changes) {
		// 	if (change.type === 'position' && change.position != null) {
		// 		const node = this.nodes.find(n => n.id === change.id);
		// 		if (node == null) continue;
		// 		if (change.position != null) node.position = change.position;
		// 		if (change.positionAbsolute != null) node.positionAbsolute = change.positionAbsolute;
		// 		if (change.dragging != null) node.dragging = change.dragging;
		// 	}
		// }
	}

	public setGraphMode(isGraphMode: boolean): void {
		this.isGraphMode = isGraphMode;
		if (this.isGraphMode) {
			// calc nodes and endges
			this.calcNodesAndEdges();
		}
	}

	public calcNodesAndEdges(): void {
		const config = json5.parse(this.workingCopy.config) as FirmwareConfiguration;
		console.log('config', config);
		const nodes = config.modules.map(m => {
			const modelEntries = [...Object.entries(m)];
			const moduleEntry = modelEntries[0];
			if (moduleEntry == null) return null;
			const [moduleType, moduleConfig] = moduleEntry;
			return {
				id: moduleConfig.name,
				data: {
					label: moduleConfig.name,
					moduleConfig: moduleConfig,
					moduleType: moduleType,
					edgeTargets: new Set(),
				},
				position: {
					x: Math.random() * 500,
					y: Math.random() * 500,
				},
				targetPosition: 'left',
				sourcePosition: 'right',
			} as Node;
		});
		const nodesWithoutNull = nodes.filter(n => n != null) as Node[];

		const edges: Edge[] = [];
		for (const node1 of nodesWithoutNull) {
			for (const node2 of nodesWithoutNull) {
				const node1Config = node1.data.moduleConfig as FirmwareConfigurationModuleProperty;
				const nodeUsesEntries = Object.entries(node1Config.uses);
				for (const [_nodeUsesKey, nodeUsesValue] of nodeUsesEntries) {
					if (typeof nodeUsesValue !== 'string') continue;
					const usesTargetNode = nodeUsesValue.includes('.')
						? nodeUsesValue.split('.')[0]
						: nodeUsesValue;
					const node2Config = node2.data.moduleConfig as FirmwareConfigurationModuleProperty;
					if (usesTargetNode === node2Config.name) {
						edges.push({
							id: `e-${node1.id}-${node2.id}`,
							source: node1.id,
							target: node2.id,
							// from: node1.id,
							// to: node2.id,
							// arrows: 'to',
							// label: nodeUsesKey,
						});
						node1.data.edgeTargets.add(node2.id);
					}
				}
			}
		}

		// sort nodes by position
		const visited = new Set<Node>();
		const startNodes = nodesWithoutNull.filter(n => !edges.some(e => e.target === n.id));
		this.layoutNodes(nodesWithoutNull, startNodes, 0, visited);
		// let x = 0
		// let y = 0
		// while (startNodes.length > 0) {
		// 	x = 0;
		// 	// startNode.position.x = x;
		// 	// startNode.position.y = y;
		// 	const sourceEdges = edges.filter(e => e.source === startNode.id);
		// 	const destNodes = nodesWithoutNull.filter(n => sourceEdges.some(e => e.target === n.id));
		// 	// while (destNodes.length > 0) {
		// 	// 	const firstNode = destNodes.shift() as Node;

		// 	// }

		// }
		this.edges.replace(edges);
		this.nodes.replace(nodesWithoutNull);
	}

	public layoutNodes(allNodes: Node[], nodes: Node[], offset: number, visited: Set<Node>): void {
		// place all nodes below each other, then collect all outgoing targets and
		// place them on the right hand side
		let y = 0;
		const next = new Set<Node>();
		for (const node of nodes) {
			node.position.x = offset;
			node.position.y = y;
			y += 50;
			const edgeTargets = node.data.edgeTargets;
			for (const targetId of edgeTargets) {
				const targetNode = allNodes.find(n => n.id === targetId);
				if (targetNode != null) {
					if (!visited.has(targetNode)) {
						next.add(targetNode);
						visited.add(targetNode);
					}
				}
			}
		}
		if (next.size == 0) return;
		this.layoutNodes(allNodes, [...next.values()], offset + 200, visited);
	}

	public setLoading(isLoading: boolean): void {
		console.log('set is loading', isLoading);
		this.isLoading = isLoading;
	}

	public setValid(isValid: boolean): void {
		console.log('set is Valid', isValid);
		this.isValid = isValid;
	}

	public async save(): Promise<boolean> {
		const result = await this.firmwareStore.uploadFirmware(this.workingCopy);
		if (result == null) {
			return false;
		} else {
			this.workingCopy.id = result.id;
			this.firmware.id = result.id;
			return true;
		}
	}

	public loadTemplate(template: string): void {
		this.template = template;
		if (template === 'MW') {
			this.workingCopy.type = 'mw';
			this.workingCopy.setConfig(
				JSON.stringify(this.firmwareStore.ctx.templateService.qSeriesTemplateMW, null, 3)
			);
		} else if (template === 'Mono') {
			this.workingCopy.type = 'mono';
			this.workingCopy.setConfig(
				JSON.stringify(this.firmwareStore.ctx.templateService.qSeriesTemplateMono, null, 3)
			);
		} else {
			this.workingCopy.type = 'd2w';
			this.workingCopy.setConfig(
				JSON.stringify(this.firmwareStore.ctx.templateService.qSeriesTemplateTuneToWhite, null, 3)
			);
		}
	}

	public validate(): Result<ValidationError, boolean> {
		// check that name is valid
		console.log('validating', this.workingCopy);
		if (this.workingCopy.name === '') return error({ type: ValidationErrorType.MissingNameError });
		// validate icon
		if (this.workingCopy.icon === null)
			return error({ type: ValidationErrorType.MissingIconError });
		// validate config
		const result = this.firmwareStore.ctx.templateService.validate(
			this.workingCopy.config,
			this.workingCopy.type
		);
		if (isOk(result)) {
			return ok(true);
		} else {
			switch (result.left.type) {
				case 'ParsingError':
					return error({ type: ValidationErrorType.ParsingError });
				case 'ValidationError':
					return error({ type: ValidationErrorType.InvalidConfig, errors: result.left.errors });
			}
		}
	}

	public async downloadFirmware(): Promise<void> {
		return this.firmware.downloadFirmware();
	}
}

export type ValidationError =
	| ValidationErrorName
	| ValidationErrorConfig
	| ValidationErrorIcon
	| ValidationErrorParsing;

export type ValidationErrorName = {
	type: ValidationErrorType.MissingNameError;
};
export type ValidationErrorIcon = {
	type: ValidationErrorType.MissingIconError;
};
export type ValidationErrorParsing = {
	type: ValidationErrorType.ParsingError;
};
export type ValidationErrorConfig = {
	type: ValidationErrorType.InvalidConfig;
	errors: string[];
};

export enum ValidationErrorType {
	MissingNameError = 'Name missing',
	MissingIconError = 'Icon missing',
	ParsingError = 'Parsing error',
	InvalidConfig = 'Invalid configuration',
}

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(HttpApi)
	.init({
		fallbackLng: 'en',
		ns: ['public', 'settings', 'sidebar', 'companies', 'common', 'admins', 'users', 'templates'],
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		load: 'languageOnly',
		backend: {
			loadPath: import.meta.env.BASE_URL + 'locales/{{lng}}/{{ns}}.json',
			allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading
			// overrideMimeType sets request.overrideMimeType("application/json")
			reloadInterval: false, // can be used to reload resources in a specific interval (useful in server environments)
		},
	});

export default i18n;

export function getCurrentLng(defaultLang = 'en'): string {
	const lang = (i18n.language || window.localStorage['i18nextLng'] || '').split('-')[0];
	return lang !== '' ? lang : defaultLang;
}

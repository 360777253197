/**
 * Meta config
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';

import { WebIconType } from '@thingos/design-icons';

import { useStores } from '../stores';
import { ConfigurationIconSelection } from './ConfigurationIconSelection';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}
export const ConfigurationExtra: React.FunctionComponent<Props> = observer(_props => {
	const { firmwareStore } = useStores();
	const { firmwareSelection } = firmwareStore;
	const onValueChange = React.useCallback(
		(v: WebIconType) => firmwareSelection?.workingCopy.setIcon(v),
		[firmwareSelection]
	);

	if (firmwareSelection == null) return null;

	const { workingCopy } = firmwareSelection;
	const { icon } = workingCopy;

	return (
		<Grid padding={4} spacing={1} container>
			<Grid item>
				<Typography variant="subtitle2" color="textSecondary">
					App Information
				</Typography>
				<Typography variant="body2" color="textSecondary">
					In case you want to customize the visual appearance of your product inside the Meshthings
					App, please enter the needed information.
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="subtitle2" color="textSecondary">
					Icon
				</Typography>
				<ConfigurationIconSelection value={icon} onValueChange={onValueChange} />
			</Grid>
		</Grid>
	);
});

/**
 * Firmware config store
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { makeObservable } from 'mobx';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CompanyStoreContext {
	// firmwareService: FirmwareService;
	// templateService: TemplateService;
	// loginStore: LoginStore;
}
export class CompanyStore {
	// public firmwares = observable.array<Firmware>([]);
	// public isLoading = false;
	public ctx: CompanyStoreContext;
	// public sortByName: TableHeaderSortDirection | null = null;
	// public firmwareSelection: FirmwareSelection | null = null;
	public selectedCompany = new Company(this, 'test-company-id');
	// public get firmwareData(): Firmware[] {
	// 	if (this.sortByName === 'asc') {
	// 		return this.firmwares.sort((a, b) => a.name.localeCompare(b.name));
	// 	} else if (this.sortByName === 'desc') {
	// 		return this.firmwares.sort((a, b) => b.name.localeCompare(a.name));
	// 	}
	// 	return this.firmwares.slice();
	// }

	constructor(ctx: CompanyStoreContext) {
		this.ctx = ctx;
		makeObservable(this, {
			// isLoading: observable,
			// firmwares: observable,
			// firmwareSelection: observable,
			// sortByName: observable,
			// firmwareData: computed,
			// setLoading: action,
			// setSortByName: action,
			// setFirmwareSelection: action,
			// addFirmware: action,
			// setFirmwareList: action,
		});
	}

	// public setLoading(isLoading: boolean): void {
	// 	console.log('setLoading', isLoading);
	// 	this.isLoading = isLoading;
	// }

	// public setSortByName(sortByName: TableHeaderSortDirection): void {
	// 	this.sortByName = sortByName;
	// }

	// public setFirmwareSelection(firmware: Firmware): void {
	// 	console.log('setFirmwareSelection', firmware);
	// 	this.firmwareSelection = new FirmwareSelection(this, firmware);
	// }

	// public addFirmware(firmware: Firmware): void {
	// 	console.log('add firmware', firmware);
	// 	this.firmwares.push(firmware);
	// 	console.log(this.firmwares);
	// }

	// public setFirmwareList(firmwares: FirmwareJson[]): void {
	// 	this.firmwares.replace(firmwares.map(f => new Firmware(this, f)));
	// }

	// public async loadFirmwares(): Promise<void> {
	// 	this.setLoading(true);
	// 	const fws = await this.ctx.firmwareService.getFirmwares();
	// 	this.setFirmwareList(fws);
	// 	this.setLoading(false);
	// }

	// public createFirmware(): void {
	// 	this.setFirmwareSelection(
	// 		new Firmware(this, {
	// 			config: null,
	// 			icon: 'devices/bulb',
	// 			id: null,
	// 			name: '',
	// 			resourceId: '',
	// 			version: '1.0',
	// 		})
	// 	);
	// }

	// public async uploadFirmware(firmware: Firmware): Promise<boolean> {
	// 	const result = await this.ctx.firmwareService.postFirmware(firmware.asJson());
	// 	if (result != null) this.addFirmware(new Firmware(this, result));
	// 	return true;
	// }

	// // loads firmware selection, if null, it will create a new one
	// public async loadSelection(firmwareId: string | null): Promise<void> {
	// 	if (firmwareId == null || firmwareId === 'new') {
	// 		this.createFirmware();
	// 	} else {
	// 		const fw = this.firmwares.find(fw => fw.id === firmwareId);
	// 		if (fw) {
	// 			this.setFirmwareSelection(fw);
	// 		} else {
	// 			// const fw = await this.ctx.firmwareService.getFirmware(firmwareId);
	// 			// this.setFirmwareSelection(new Firmware(this, fw));
	// 		}
	// 	}
	// }

	// public async downloadFirmware(firmwareId: string, fileName = 'fw.hex'): Promise<void> {
	// 	const fw = await this.ctx.firmwareService.downloadFirmware(firmwareId);
	// 	const blob = new Blob([fw], { type: 'application/octet-binary' });
	// 	const url = URL.createObjectURL(blob);
	// 	const element = document.createElement('a');
	// 	element.href = url;
	// 	element.setAttribute('download', fileName);
	// 	element.style.display = 'none';
	// 	document.body.appendChild(element);
	// 	element.click();
	// 	document.body.removeChild(element);
	// }
}

export class Company {
	public id: string;
	public users: User[];
	public companyStore: CompanyStore;

	constructor(companyStore: CompanyStore, id: string) {
		this.id = id;
		this.users = [];
		this.companyStore = companyStore;
	}

	public loadUsers(): Promise<void> {
		return Promise.resolve();
	}
}

export class User {
	public name: string;
	public email: string;
	public company: Company;

	constructor(name: string, email: string, company: Company) {
		this.name = name;
		this.email = email;
		this.company = company;
	}
}

import { CssBaseline } from '@mui/material';
import React, { Fragment } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { AppRoot, LoadingPage, SnackbarProvider, ThemeProvider } from '@thingos/thingos-components';

import i18n from './i18n';
import { Root } from './pages/Root';
import { StoreContext, stores } from './stores';

export const App: React.FC = () => {
	return (
		<Fragment>
			<CssBaseline />
			<StoreContext.Provider value={stores}>
				<ThemeProvider>
					<I18nextProvider i18n={i18n}>
						<AppRoot>
							<SnackbarProvider sx={{ flex: 1, display: 'flex' }}>
								<React.Suspense fallback={<LoadingPage />}>
									<BrowserRouter basename={import.meta.env.BASE_URL}>
										<div
											id="measure-layer"
											style={{
												display: 'inline-block',
												position: 'absolute',
												visibility: 'hidden',
												zIndex: -1,
											}}
										/>
										<Root />
									</BrowserRouter>
								</React.Suspense>
							</SnackbarProvider>
						</AppRoot>
					</I18nextProvider>
				</ThemeProvider>
			</StoreContext.Provider>
		</Fragment>
	);
};

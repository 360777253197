import { useNavigate } from 'react-router-dom';

export const privatePaths = {
	firmwares: '/',
	users: '/users',
	firmware: '/firmware/:firmwareId',
	settings: '/settings',
};

export const privateRoutes = Object.values(privatePaths);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useNavigator() {
	const navigate = useNavigate();

	return {
		push: {
			configurationPage(firmwareId: string) {
				navigate(`/firmware/${firmwareId}`);
			},
		},
	};
}

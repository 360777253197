export const exampleQSeriesFw = {
	version: '1.0',
	type: 'Q36MW',
	metadata: {
		ledCount: 36,
		name: 'Q36 MW 2200K-5000K',
	},
	properties: {
		setupButtonActiveHigh: false,
		statusLedActiveHigh: true,
	},
	modules: [
		{
			DigitalOut: {
				name: 'tpsEnable',
				uses: {
					pin: 2,
				},
				properties: {
					value: true,
				},
			},
		},
		{
			DigitalOut: {
				name: 'tpsVinEnable',
				uses: {
					pin: 22,
				},
				properties: {
					value: true,
				},
			},
		},
		{
			DigitalOutSplitTwo: {
				name: 'tpsPower',
				uses: {
					first: 'tpsEnable.out',
					second: 'tpsVinEnable.out',
				},
				properties: {},
			},
		},
		{
			DigitalOutBinaryOperation: {
				name: 'tpsPowerChannels',
				uses: {
					out: 'tpsPower.out',
				},
				properties: {
					op: 'Or',
				},
			},
		},
		{
			Spim: {
				name: 'spim',
				uses: {
					sck: 6,
					mosi: 15,
					miso: 8,
					cs: 255,
				},
				properties: {
					driverIndex: 2,
					mode: 0,
				},
			},
		},
		{
			DigitalOut: {
				name: 'cs',
				uses: {
					pin: 5,
				},
				properties: {
					value: false,
				},
			},
		},
		{
			SpimDevice: {
				name: 'spimDevice',
				uses: {
					spim: 'spim.data',
					cs: 'cs.out',
				},
				properties: {},
			},
		},
		{
			Tps92518: {
				name: 'tps',
				uses: {
					spim: 'spimDevice.data',
				},
				properties: {
					channel1Config: {
						enabled: true,
						tOff: 13,
						maxOff: 255,
						peakThreshold: 255,
					},
					channel2Config: {
						enabled: true,
						tOff: 13,
						maxOff: 255,
						peakThreshold: 255,
					},
				},
			},
		},
		{
			PwmPool: {
				name: 'pwmPool',
				uses: {},
				properties: {
					driverIndexes: [0],
					frequency: 16000000,
					count: 'Up',
					top: 1066,
					dither: 2,
				},
			},
		},
		{
			PwmOut: {
				name: 'warmPwm',
				uses: {
					pool: 'pwmPool.pool',
					pin: 3,
				},
				properties: {
					inverted: false,
					minDutyCycle: 0,
				},
			},
		},
		{
			PwmOut: {
				name: 'coldPwm',
				uses: {
					pool: 'pwmPool.pool',
					pin: 7,
				},
				properties: {
					inverted: false,
					minDutyCycle: 0,
				},
			},
		},
		{
			AnalogOutSplitRange: {
				name: 'warmOut',
				uses: {
					upper: 'tps.channel1',
					lower: 'warmPwm.in',
				},
				properties: {
					split: 0.3,
				},
			},
		},
		{
			AnalogOutSplitRange: {
				name: 'coldOut',
				uses: {
					upper: 'tps.channel2',
					lower: 'coldPwm.in',
				},
				properties: {
					split: 0.3,
				},
			},
		},
		{
			AnalogOutTransferFunction: {
				name: 'warm',
				uses: {
					out: 'warmPowerManager.in',
				},
				properties: {
					function: [
						{
							x: 0,
							y: 0,
						},
						{
							x: 1,
							y: 1,
						},
					],
				},
			},
		},
		{
			AnalogOutTransferFunction: {
				name: 'cold',
				uses: {
					out: 'coldPowerManager.in',
				},
				properties: {
					function: [
						{
							x: 0,
							y: 0,
						},
						{
							x: 1,
							y: 1,
						},
					],
				},
			},
		},
		{
			AnalogOutBinaryOperation: {
				name: 'coldBinOp',
				uses: {
					out: 'cold.in',
				},
				properties: {
					op: 'Minimum',
				},
			},
		},
		{
			AnalogOutBinaryOperation: {
				name: 'warmBinOp',
				uses: {
					out: 'warm.in',
				},
				properties: {
					op: 'Minimum',
				},
			},
		},
		{
			AnalogOutSplitTwo: {
				name: 'tempSplit',
				uses: {
					first: 'warmBinOp.left',
					second: 'coldBinOp.left',
				},
				properties: {},
			},
		},
		{
			AnalogOutBinaryOperation: {
				name: 'combineLimitOff',
				uses: {
					out: 'tempSplit.in',
				},
				properties: {
					op: 'Minimum',
				},
			},
		},
		{
			AnalogOutTransferFunction: {
				name: 'temperatureLimit',
				uses: {
					out: 'combineLimitOff.right',
				},
				properties: {
					function: [
						{
							x: -40,
							y: 1,
						},
						{
							x: 40,
							y: 1,
						},
						{
							x: 50,
							y: 1,
						},
						{
							x: 60,
							y: 0.9,
						},
						{
							x: 80,
							y: 0.3,
						},
						{
							x: 120,
							y: 0,
						},
					],
				},
			},
		},
		{
			AnalogOutHysteresis: {
				name: 'temperatureOff',
				uses: {
					out: 'combineLimitOff.left',
				},
				properties: {
					range: {
						minimum: 40,
						maximum: 80,
					},
					inverted: true,
				},
			},
		},
		{
			AnalogOutSplitTwo: {
				name: 'temperature',
				uses: {
					first: 'temperatureOff.in',
					second: 'temperatureLimit.in',
				},
				properties: {},
			},
		},
		{
			AnalogOutTransferFunction: {
				name: 'analogTestTransfer',
				uses: {
					out: 'temperature.in',
				},
				properties: {
					function: [
						{
							x: 0,
							y: -40,
						},
						{
							x: 1.3,
							y: -40,
						},
						{
							x: 1.41428571428571,
							y: -20,
						},
						{
							x: 1.51621621621622,
							y: 0,
						},
						{
							x: 1.60769230769231,
							y: 20,
						},
						{
							x: 1.72857142857143,
							y: 40,
						},
						{
							x: 1.83333333333333,
							y: 60,
						},
						{
							x: 1.925,
							y: 80,
						},
						{
							x: 2.00588235294118,
							y: 100,
						},
						{
							x: 2.1,
							y: 120,
						},
						{
							x: 4,
							y: 120,
						},
					],
				},
			},
		},
		{
			AnalogIn: {
				name: 'analogIn',
				uses: {
					pin: 31,
				},
				properties: {
					samplingInterval: 100,
				},
			},
		},
		{
			AnalogInToOut: {
				name: 'analogInToOut',
				uses: {
					in: 'analogIn.in',
					out: 'runningAverage.in',
				},
				properties: {
					enabled: true,
				},
			},
		},
		{
			NotificationBlinkTunableWhite: {
				name: 'blink2',
				uses: {
					outWarm: 'warmBinOp.right',
					outCold: 'coldBinOp.right',
				},
				properties: {
					types: ['SetupButton'],
					range: {
						minimum: {
							warm: 0.5,
							cold: 0.5,
						},
						maximum: {
							warm: 0,
							cold: 0,
						},
					},
				},
			},
		},
		{
			NotificationBlinkTunableWhite: {
				name: 'blink1',
				uses: {
					outWarm: 'blink2.inWarm',
					outCold: 'blink2.inCold',
				},
				properties: {
					types: ['Attention'],
					range: {
						minimum: {
							warm: 0,
							cold: 0,
						},
						maximum: {
							warm: 0.5,
							cold: 0.5,
						},
					},
				},
			},
		},
		{
			TunableWhiteLight: {
				name: 'light',
				uses: {
					warm: 'blink1.inWarm',
					cold: 'blink1.inCold',
				},
				properties: {
					defaultPowerUpBehavior: 'Restore',
					lightnessRange: {
						minimum: 6553,
						maximum: 65535,
					},
					defaultLightness: 65535,
					temperatureRange: {
						minimum: 2200,
						maximum: 5000,
					},
					defaultTemperature: 3000,
				},
			},
		},
		{
			Comp: {
				name: 'powerLoss',
				uses: {
					pin: 30,
				},
				properties: {
					threshold: {
						minimum: 1.7,
						maximum: 1.8,
					},
				},
			},
		},
		{
			PowerFailureDigitalIn: {
				name: 'powerFailure',
				uses: {
					in: 'powerLoss.in',
				},
				properties: {},
			},
		},
		{
			StatusLed: {
				name: 'statusLed',
				uses: {},
				properties: {
					on: false,
				},
			},
		},
		{
			Configuration: {
				name: 'configuration',
				uses: {},
				properties: {},
			},
		},
	],
};

/**
 * Root page of the private routes, making sure the user is loged in.
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { LinksFooter, Logo, Public, Sidebar } from '@thingos/thingos-components';

import { SidebarContent } from '../components/SidebarContent';
import { useStores } from '../stores';
import { ConfigurationPage } from './ConfigurationPage';
import { FirmwareListPage } from './FirmwareListPage';
import { SettingsPage } from './SettingsPage';
import { privatePaths } from './private';
import { LoginPage } from './public/LoginPage';
import { RegistrationPage } from './public/RegistrationPage';
import { publicPaths } from './public/public';

export const Root: React.FC = () => (
	<Routes>
		<Route
			path={publicPaths.login}
			element={
				<PublicWrapper>
					<LoginPage />
				</PublicWrapper>
			}
		/>
		<Route
			path={publicPaths.registration}
			element={
				<PublicWrapper>
					<RegistrationPage />
				</PublicWrapper>
			}
		/>

		<Route
			path={privatePaths.firmware}
			element={
				<Private>
					<ConfigurationPage />
				</Private>
			}
		/>
		<Route
			path={privatePaths.firmwares}
			element={
				<Private>
					<FirmwareListPage />
				</Private>
			}
		/>
		<Route
			path={privatePaths.settings}
			element={
				<Private>
					<SettingsPage />
				</Private>
			}
		/>
	</Routes>
);

const Private: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { firmwareStore } = useStores();
	const navigate = useNavigate();

	React.useEffect(() => {
		firmwareStore.loadFirmwares().then(r => {
			if (r === 'login') {
				navigate('/login');
			}
		});
	}, [firmwareStore, navigate]);

	return (
		<Sidebar.Layout SidebarLogo={<Sidebar.Logo />} SidebarContent={<SidebarContent />}>
			{children}
		</Sidebar.Layout>
	);
};

const PublicWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { t } = useTranslation('public');
	return (
		<Public
			Logo={<Logo />}
			Footer={
				<LinksFooter
					links={[
						{
							title: t('footer.support'),
							to: 'mailto:hcm-auto-setup@thingos.io',
						},
						{
							title: `© ${new Date().getFullYear()} ThingOS GmbH`,
							to: 'https://thingos.io',
						},
					]}
				/>
			}
		>
			{children}
		</Public>
	);
};

import * as React from 'react';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Form, LoginData, useSnackbar } from '@thingos/thingos-components';

import { useStores } from '../../stores';
import { privatePaths } from '../private';

//import { publicPaths } from './public';

export const LoginPage: React.FC = observer(() => {
	const { enqueueSnackbar } = useSnackbar();
	const { loginStore } = useStores();
	const navigate = useNavigate();
	const { t } = useTranslation('public');
	const { t: common } = useTranslation('common');
	const labels = {
		title: t('login.title'),
		submitButton: t('login.login'),
		email: common('forms.emailLabel'),
		emailPlaceholder: common('forms.emailPlaceholder'),
		password: t('login.password'),
		warningRequired: common('forms.required'),
		warningInvalid: common('forms.invalidMail'),
		forgotPassword: t('login.forgotPassword'),
		rememberLabel: t('login.remember'),
		registerQuestion: t('login.remember'),
		registerLink: t('login.remember'),
	};

	const onLogin = React.useCallback(
		(formData: LoginData) => {
			console.log('pressed login', formData);
			if (loginStore.isProcessing) return Promise.resolve();
			return loginStore
				.login(formData.email, formData.password, formData.remember)
				.then(isLoggedIn => {
					if (isLoggedIn) {
						navigate(privatePaths.firmwares);
					} else {
						const errorLabel = t('login.error') as string;
						enqueueSnackbar(errorLabel, { variant: 'error' });
					}
				});
		},
		[enqueueSnackbar, t, navigate, loginStore]
	);

	return (
		<Grid container flex={1} alignItems="center" justifyContent="center">
			<Form.CardContainer>
				<Form.Title>{labels.title}</Form.Title>
				<Form.Login
					style={{ minWidth: 400 }}
					requestPasswordLink="/"
					onLogin={onLogin}
					labels={labels}
				/>
			</Form.CardContainer>
		</Grid>
	);
});

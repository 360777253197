import * as E from 'fp-ts/lib/Either';

export function timerPromise(ms: number): Promise<void> {
	return new Promise(resolve => {
		setTimeout(resolve, ms);
	});
}

export type Result<Err = never, Val = never> = E.Either<Err, Val>;

export function ok<Err = never, Val = never>(value: Val): Result<Err, Val> {
	return E.right(value);
}

export function error<Err = never, Val = never>(err: Err): Result<Err, Val> {
	return E.left(err);
}

export function isOk<Err, Val>(result: E.Either<Err, Val>): result is E.Right<Val> {
	return E.isRight(result);
}

export function isError<Err, Val>(result: E.Either<Err, Val>): result is E.Left<Err> {
	return E.isLeft(result);
}

export function uuidv4(): string {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export function snakeCase(input: string): string {
	return input.toLowerCase().replace(/\s/g, '_');
}

/**
 * Registraton page
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { VStack } from '@domir/react-stacks';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Form } from '@thingos/thingos-components';

export const RegistrationPage = observer(() => {
	const { t } = useTranslation('public');
	const { t: common } = useTranslation('common');
	const labels = {
		title: t('login.title'),
		submitButton: t('login.login'),
		email: common('forms.emailLabel'),
		emailPlaceholder: common('forms.emailPlaceholder'),
		password: t('login.password'),
		warningRequired: common('forms.required'),
		warningInvalid: common('forms.invalidMail'),
		forgotPassword: t('login.forgotPassword'),
		rememberLabel: t('login.remember'),
	};

	// const theme = useTheme();
	// return <pre>{JSON.stringify(theme, null, 2)}</pre>;
	return (
		<VStack flex alignItems="center" justifyContent="center">
			{/* <p>Nice</p> */}

			<Form.Registration
				style={{ minWidth: 400 }}
				// requestPasswordLink="/"

				onRegister={() => {
					console.log('onRegister');
				}}
				labels={{
					...labels,
					name: '',
					namePlaceholder: '',
					passwordRepeat: '',
					warningMustMatch: '',
					email: '',
					emailPlaceholder: '',
					password: '',
					submitButton: '',
					warningRequired: '',
				}}
			/>
		</VStack>
	);
});

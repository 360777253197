/**
 * Configuration page
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import * as React from 'react';
import { VStack } from '@domir/react-stacks';
import { Stack } from '@mui/material';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';

import {
	Button,
	DeletionDialog,
	HStack,
	PageHeader,
	useSnackbar,
} from '@thingos/thingos-components';

import { ConfigurationEditorRef } from '../components/ConfigurationEditor';
import { ConfigurationEditorManager } from '../components/ConfigurationEditorManager';
import { ConfigurationExtra } from '../components/ConfigurationExtra';
import { MetaConfiguration } from '../components/ConfigurationMeta';
import { isError } from '../services/utils';
import { useStores } from '../stores';
import { ValidationErrorType } from '../stores/firmwareStore';

export const ConfigurationPage: React.FC = observer(() => {
	const navigate = useNavigate();
	const { firmwareStore } = useStores();
	const { firmwareId } = useParams<'firmwareId'>();
	const { enqueueSnackbar } = useSnackbar();
	const editorRef = React.useRef<ConfigurationEditorRef>(null);

	const { firmwareSelection } = firmwareStore;

	const [deleteDialogIsOpen, setDeleteDialogState] = React.useState(false);

	const onDelete = React.useCallback(() => setDeleteDialogState(true), [setDeleteDialogState]);

	const onHandleDialogCancel = React.useCallback(
		() => setDeleteDialogState(false),
		[setDeleteDialogState]
	);

	const onHandleDialogOk = React.useCallback(async () => {
		const id = firmwareSelection?.firmware.id;
		if (id != null) {
			firmwareStore.deleteFirmware(id);
			navigate(-1);
		} else {
			enqueueSnackbar('Failed to delete firmware.', {
				variant: 'error',
			});
		}
		setDeleteDialogState(false);
	}, [setDeleteDialogState, firmwareStore, navigate, enqueueSnackbar, firmwareSelection]);

	const onSave = React.useCallback(async () => {
		console.log('clicked save');
		if (firmwareSelection == null) return false;
		// first format the configuration
		editorRef.current?.format();

		// set it to the working copy
		firmwareSelection.workingCopy.setConfig(editorRef.current?.getValue() ?? '');

		// validate it
		const result = firmwareSelection.validate();
		if (isError(result)) {
			console.log('validation error', result.left);
			switch (result.left.type) {
				case ValidationErrorType.MissingNameError:
					enqueueSnackbar('Name cannot be empty, please enter a name.', {
						variant: 'error',
					});
					break;
				case ValidationErrorType.MissingIconError:
					enqueueSnackbar('No icon selected, please select one of the provided icons.', {
						variant: 'error',
					});
					break;
				case ValidationErrorType.InvalidConfig:
					enqueueSnackbar('Invalid config, please check and fix warnings in the editor.', {
						variant: 'error',
					});
					break;
				case ValidationErrorType.ParsingError:
					enqueueSnackbar('Parsing error, please check and fix warnings in the editor.', {
						variant: 'error',
					});
					break;
			}
			return false;
		}

		// save it to server
		return firmwareSelection?.save().then(result => {
			if (!result) {
				enqueueSnackbar('Could not save firmware', { variant: 'error' });
			}
			return result;
		});
	}, [firmwareSelection, enqueueSnackbar]);

	const onClickSave = React.useCallback(async () => {
		if (await onSave()) {
			navigate('/');
		}
	}, [onSave, navigate]);

	const onClickDownload = React.useCallback(async () => {
		if (await onSave()) {
			firmwareSelection?.downloadFirmware();
		}
	}, [onSave, firmwareSelection]);

	// load the firmware according to the url
	React.useEffect(() => {
		if (firmwareId != null) firmwareStore.loadSelection(firmwareId);
	}, [firmwareStore, firmwareId]);

	return (
		<VStack flex>
			<PageHeader.Layout
				title="Firmwares"
				RightDetail={
					<Stack direction="row" spacing={1}>
						<Button color="primary" variant="contained" onClick={onDelete}>
							Delete
						</Button>

						<DeletionDialog
							text={{
								title: 'Delete firmware',
								info: 'Delete this firmware configuration',
								cancel: 'Cancel',
								confirm: 'Delete',
							}}
							open={deleteDialogIsOpen}
							onCancel={onHandleDialogCancel}
							onDelete={onHandleDialogOk}
						/>
						<Button color="primary" variant="contained" onClick={onClickDownload}>
							Download firmware
						</Button>
						<Button color="primary" variant="contained" onClick={onClickSave}>
							Validate and Save
						</Button>
					</Stack>
				}
			/>

			<HStack flex={1}>
				<VStack flex={8}>
					<ConfigurationEditorManager editorRef={editorRef} />
				</VStack>
				<VStack flex={4}>
					<MetaConfiguration />
					<ConfigurationExtra />
				</VStack>
			</HStack>
		</VStack>
	);
});

/**
 * Loading component
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { useTheme } from '@emotion/react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';

export const Loading: React.FC = observer(() => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor:
					theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
			}}
		>
			<Stack spacing={1} alignItems="center">
				<CircularProgress size={24} />
				<Typography>Loading...</Typography>
			</Stack>
		</Box>
	);
});

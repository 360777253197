import Ajv from 'ajv';
import json5 from 'json5';

import { FirmwareType } from '@thingos/firmware-configurator-shared';

import {
	qSeriesDimmToWarmTemplate,
	qSeriesMWTemplate,
	qSeriesMonoTemplate,
	qSeriesSchema,
} from '../templates';
import { Result, error, ok } from './utils';

const ajv = new Ajv({ strict: false });
const validator = ajv.compile(qSeriesSchema);

type ValidationError = {
	type: 'ValidationError';
	errors: string[];
};
type ParsingError = {
	type: 'ParsingError';
};
function validate(
	config: string,
	_type: FirmwareType
): Result<ValidationError | ParsingError, boolean> {
	try {
		const isValid = validator(json5.parse(config));
		if (isValid) {
			return ok(true);
		} else {
			const errMessages = validator.errors?.map(e => e.message ?? '') || [];
			return error({ type: 'ValidationError', errors: errMessages });
		}
	} catch (e) {
		return error({ type: 'ParsingError' });
	}
}

export const templateService = {
	qSeriesTemplateMW: qSeriesMWTemplate,
	qSeriesTemplateMono: qSeriesMonoTemplate,
	qSeriesTemplateTuneToWhite: qSeriesDimmToWarmTemplate,

	validate,
};

export type TemplateService = typeof templateService;

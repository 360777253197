/**
 * Settings page
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import * as React from 'react';

export const SettingsPage: React.FC = () => {
	return <div />;
};

/**
 * Meta config
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import ReactFlow, { Controls } from 'react-flow-renderer';

import { useStores } from '../stores';

export const ConfigurationGraph: React.FC = observer(() => {
	const { firmwareStore } = useStores();
	const firmwareSelection = firmwareStore.firmwareSelection;
	if (firmwareSelection == null) return null;
	const nodes = firmwareSelection.nodes.slice();
	const edges = firmwareSelection.edges.slice();
	const onHandleNodeChanges = firmwareSelection.onHandleNodeChanges;
	// const positions = nodes.map(node => node.position.x);
	// console.log('render ConfigurationGraph', nodes, positions);

	return (
		<ReactFlow nodes={nodes} edges={edges} onNodesChange={onHandleNodeChanges} fitView>
			<Controls />
		</ReactFlow>
	);
});

// export default Flow;

// type ChangeEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

// export interface ConfigurationEditorRef {
// 	format: () => void;
// 	getValue: () => string;
// }

// interface Props {
// 	ref?: React.Ref<ConfigurationEditorRef> | undefined;
// }
// export const ConfigurationGraph: React.FunctionComponent<Props> = observer(
// 	React.forwardRef((_props, ref: React.Ref<ConfigurationEditorRef>) => {
// 		const theme = useTheme();
// 		const { firmwareStore } = useStores();
// 		const { firmwareSelection } = firmwareStore;
// 		const schema = qSeriesSchema;

// 		const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
// 		const monacoRef = React.useRef<typeof monaco | null>(null);

// 		React.useImperativeHandle(ref, () => ({
// 			format: () => {
// 				editorRef.current?.getAction('editor.action.formatDocument').run();
// 			},
// 			getValue: () => {
// 				return editorRef.current?.getValue() ?? '';
// 			},
// 		}));

// 		const handleEditorWillMount: BeforeMount = React.useCallback(
// 			monaco => {
// 				monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
// 					validate: true,
// 					allowComments: true,
// 					schemaRequest: 'ignore',
// 					schemaValidation: 'error',
// 					schemas: [
// 						{
// 							uri: 'http://myserver/foo-schema.json',
// 							fileMatch: ['*'],
// 							schema,
// 						},
// 					],
// 				});
// 			},
// 			[schema]
// 		);

// 		React.useEffect(() => {
// 			monacoRef.current?.languages.json.jsonDefaults.setDiagnosticsOptions({
// 				validate: true,
// 				allowComments: true,
// 				schemaRequest: 'ignore',
// 				schemaValidation: 'error',
// 				schemas: [
// 					{
// 						uri: 'http://myserver/foo-schema.json',
// 						fileMatch: ['*'],
// 						schema,
// 					},
// 				],
// 			});
// 		}, [schema]);

// 		const handleEditorDidMount: OnMount = React.useCallback((editor, monaco) => {
// 			editorRef.current = editor;
// 			monacoRef.current = monaco;
// 		}, []);

// 		const handleOnChange: OnChange = React.useCallback(
// 			_evt => {
// 				if (_evt != null) {
// 					firmwareSelection?.workingCopy.setConfig(_evt);
// 				}
// 				firmwareSelection?.setValid(false);
// 			},
// 			[firmwareSelection]
// 		);

// 		if (firmwareSelection == null) return null;
// 		const { workingCopy } = firmwareSelection;
// 		const { config } = workingCopy;

// 		return (
// 			<Editor
// 				theme={theme.palette.mode === 'dark' ? 'vs-dark' : 'vs'}
// 				beforeMount={handleEditorWillMount}
// 				onMount={handleEditorDidMount}
// 				height="100%"
// 				defaultLanguage="json"
// 				value={config}
// 				onChange={handleOnChange}
// 				loading={<Loading />}
// 			/>
// 		);
// 	})
// );

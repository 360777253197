/**
 * Icon selection component
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { useTheme } from '@emotion/react';
import { Grid, IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Icons, WebIconType } from '@thingos/design-icons';

const icons: WebIconType[] = [
	'devices/bulb',
	'devices/bulb_hue',
	'devices/bulb_lifx',
	'devices/ledband',
	'devices/lampDesk',
	'devices/lampStand',
	'devices/lampTable',
	'devices/lampTable2',
	'devices/lampWall',
	'devices/devBoard',
];

interface Props {
	onValueChange(icon: WebIconType): void;
	value: WebIconType;
}
export const ConfigurationIconSelection: React.FC<Props> = observer(props => {
	return (
		<Grid container>
			{icons.map(kind => (
				<ConfigurationIcon key={kind} {...props} kind={kind} />
			))}
		</Grid>
	);
});

const ConfigurationIcon: React.FC<Props & { kind: WebIconType }> = observer(props => {
	const theme = useTheme();
	const { onValueChange, kind, value } = props;
	const onClickIcon = React.useCallback(() => onValueChange(kind), [onValueChange, kind]);
	return (
		<Grid item key={kind}>
			<IconButton onClick={onClickIcon}>
				<Icons
					color={value === kind ? theme.palette.primary.main : theme.palette.text.primary}
					width="24"
					height="24"
					kind={kind}
				/>
			</IconButton>
		</Grid>
	);
});

/**
 * Firmware table
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import * as React from 'react';
import { useTheme } from '@emotion/react';
import { Box, Fade, Typography } from '@mui/material';
import { observer } from 'mobx-react';

import { Icons } from '@thingos/design-icons';
import { Table } from '@thingos/thingos-components';

import { useNavigator } from '../pages/private';
import { useStores } from '../stores';
import type { Firmware } from '../stores/firmwareStore';
import { Loading } from './Loading';

export const FirmwareTable: React.FC = observer(() => {
	const { firmwareStore } = useStores();
	const { firmwareData, isLoading } = firmwareStore;

	if (isLoading) {
		return <Loading />;
	}

	return (
		<Fade in style={{ flex: 1 }}>
			<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>
				{/* <TableBody style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: 16 }}> */}
				<Table.Body>
					{firmwareData.map(fw => (
						<FirmwareRow key={fw.id} firmware={fw} />
					))}
				</Table.Body>
			</Box>
		</Fade>
	);
});

interface FirmwareRowProps {
	firmware: Firmware;
}
const FirmwareRow: React.FC<FirmwareRowProps> = observer(props => {
	const theme = useTheme();
	const navigator = useNavigator();
	const { firmware } = props;
	const { name, version } = firmware;

	const onClickDownload = React.useCallback(() => firmware.downloadFirmware(), [firmware]);
	const onClickRow = React.useCallback(() => {
		if (firmware.id != null) {
			navigator.push.configurationPage(firmware.id);
		}
	}, [navigator, firmware]);

	return (
		<Table.RowButton onClick={onClickRow}>
			<Table.Cell fix={50}>
				<Box
					sx={{
						height: 50,
						width: 50,
						backgroundColor: theme.palette.grey[100],
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Icons kind={firmware.icon} />
				</Box>
			</Table.Cell>
			<Table.Cell flex={3}>
				<Typography
					style={{ flex: 1, textAlign: 'start', paddingLeft: 8, paddingRight: 8 }}
					color="textSecondary"
					variant="body2"
				>
					{name}
				</Typography>
			</Table.Cell>
			<Table.Cell flex={2}>
				<Typography
					style={{ flex: 1, textAlign: 'start', paddingLeft: 8, paddingRight: 8 }}
					color="textSecondary"
					variant="body2"
				>
					{version}
				</Typography>
			</Table.Cell>
			<Table.Cell flex={1}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Box />
					<Table.CellButton style={{ margin: 4 }} variant="contained" onClick={onClickDownload}>
						Download
					</Table.CellButton>
				</Box>
			</Table.Cell>
		</Table.RowButton>
	);
});

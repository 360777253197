import { observer } from 'mobx-react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Page, PageHeader, Table } from '@thingos/thingos-components';

import { FirmwareTable } from '../components/FirmwareTable';
import { useStores } from '../stores';

export const FirmwareListPage: React.FC = observer(() => {
	const navigate = useNavigate();
	const { firmwareStore } = useStores();

	// React.useEffect(() => {
	// 	firmwareStore.loadFirmwares();
	// }, [firmwareStore]);

	const onClickNewFirmware = React.useCallback(() => {
		firmwareStore.createFirmware();
		navigate('/firmware/new');
	}, [firmwareStore, navigate]);

	return (
		<Page.Container>
			<PageHeader.Layout
				title="Firmwares"
				RightDetail={
					<Button color="primary" variant="contained" onClick={onClickNewFirmware}>
						New Configuration
					</Button>
				}
			>
				<Table.Header>
					<Table.HeaderCell fix={50} />
					<Table.HeaderTextCell sorted="asc" sortable flex={3} style={{ paddingLeft: 8 }}>
						Name
					</Table.HeaderTextCell>
					<Table.HeaderTextCell flex={2} style={{ paddingLeft: 8 }}>
						Version
					</Table.HeaderTextCell>
					<Table.HeaderTextCell flex={1} style={{ paddingLeft: 8 }}>
						{/*
						Tags
								*/}
					</Table.HeaderTextCell>
				</Table.Header>
			</PageHeader.Layout>
			<FirmwareTable />
		</Page.Container>
	);
});

/**
 * Login store
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { action, computed, makeObservable, observable } from 'mobx';

import type { AccountInfo } from '@thingos/firmware-configurator-shared';

import type { LoginService } from '../services/loginService';
import { isOk } from '../services/utils';

export interface LoginStoreContext {
	loginService: LoginService;
}
export class LoginStore {
	public isProcessing = false;
	public accountInfo: AccountInfo | null = null;
	public ctx: LoginStoreContext;

	public get isLoggedIn(): boolean {
		return this.accountInfo != null;
	}

	constructor(context: LoginStoreContext) {
		this.ctx = context;

		makeObservable(this, {
			isProcessing: observable,
			accountInfo: observable.shallow,

			isLoggedIn: computed,

			setAccountInfo: action,
			setProcessing: action,
		});
	}

	public setProcessing(isProcessing: boolean): void {
		console.log('set processing: ', isProcessing);
		this.isProcessing = isProcessing;
	}

	public setAccountInfo(accountInfo: AccountInfo): void {
		console.log('set account info', accountInfo);
		this.accountInfo = accountInfo;
	}

	public async login(email: string, password: string, remember: boolean): Promise<boolean> {
		this.setProcessing(true);
		const loginParams = { email, password, remember };
		const loginTask = this.ctx.loginService.login(loginParams);
		const result = await loginTask();

		if (isOk(result)) {
			this.setAccountInfo(result.right.accountInfo);
			this.setProcessing(false);
			return true;
		} else {
			// noop
			switch (result.left) {
				case 'NoSuchUser':
				case 'ServerError':
				case 'UnknownError':
				case 'WrongPassword':
				default: {
					console.log('TODO: handle login error', result.left);
					this.setProcessing(false);
					return false;
				}
			}
		}
	}

	public async changePassword(_newPassword: string, _newPasswordRepeat: string): Promise<boolean> {
		return Promise.resolve(true);
	}

	public async logout(): Promise<boolean> {
		this.setProcessing(true);
		const result = await this.ctx.loginService.logout()();
		this.setProcessing(false);
		return result._tag === 'Right';
	}
}

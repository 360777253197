export const qSeriesMWTemplate = {
	version: '1.0',
	type: 'QMW',
	metadata: {
		ledCount: 36,
		name: 'Q36 MW 2700K-5000K',
	},
	properties: {
		setupButtonActiveHigh: false,
		statusLedActiveHigh: true,
	},
	modules: [
		{
			DigitalOut: {
				name: 'tpsEnable',
				uses: {
					pin: 2,
				},
				properties: {
					value: true,
				},
			},
		},
		{
			Spim: {
				name: 'spim',
				uses: {
					sck: 6,
					mosi: 15,
					miso: 8,
					cs: 255,
				},
				properties: {
					driverIndex: 2,
					mode: 0,
				},
			},
		},
		{
			DigitalOut: {
				name: 'cs',
				uses: {
					pin: 5,
				},
				properties: {
					value: false,
				},
			},
		},
		{
			SpimDevice: {
				name: 'spimDevice',
				uses: {
					spim: 'spim.data',
					cs: 'cs.out',
				},
				properties: {},
			},
		},
		{
			Tps92518: {
				name: 'tps',
				uses: {
					spim: 'spimDevice.data',
				},
				properties: {
					channel1Config: {
						enabled: true,
						tOff: 25,
						maxOff: 255,
						peakThreshold: 255,
					},
					channel2Config: {
						enabled: true,
						tOff: 25,
						maxOff: 255,
						peakThreshold: 255,
					},
				},
			},
		},
		{
			PwmPool: {
				name: 'pwmPool',
				uses: {},
				properties: {
					driverIndexes: [0],
					frequency: 16000000,
					count: 1,
					top: 1066,
				},
			},
		},
		{
			PwmOut: {
				name: 'warmPwm',
				uses: {
					pool: 'pwmPool.pool',
					pin: 7,
				},
				properties: {
					inverted: false,
					minDutyCycle: 0,
				},
			},
		},
		{
			PwmOut: {
				name: 'coldPwm',
				uses: {
					pool: 'pwmPool.pool',
					pin: 3,
				},
				properties: {
					inverted: false,
					minDutyCycle: 0,
				},
			},
		},
		{
			AnalogOutSplitRange: {
				name: 'warmOut',
				uses: {
					upper: 'tps.channel2',
					lower: 'warmPwm.in',
				},
				properties: {
					split: 0.05,
				},
			},
		},
		{
			AnalogOutSplitRange: {
				name: 'coldOut',
				uses: {
					upper: 'tps.channel1',
					lower: 'coldPwm.in',
				},
				properties: {
					split: 0.05,
				},
			},
		},
		{
			AnalogOutTransferFunction: {
				name: 'warm',
				uses: {
					out: 'warmOut.in',
				},
				properties: {
					function: [
						{
							x: 0,
							y: 0,
						},
						{
							x: 1,
							y: 1,
						},
					],
				},
			},
		},
		{
			AnalogOutTransferFunction: {
				name: 'cold',
				uses: {
					out: 'coldOut.in',
				},
				properties: {
					function: [
						{
							x: 0,
							y: 0,
						},
						{
							x: 1,
							y: 1,
						},
					],
				},
			},
		},
		{
			TunableWhiteLight: {
				name: 'light',
				uses: {
					warm: 'warm.in',
					cold: 'cold.in',
				},
				properties: {
					defaultPowerUpBehavior: 'Restore',
					lightnessRange: {
						minimum: 6553,
						maximum: 65535,
					},
					defaultLightness: 65535,
					temperatureRange: {
						minimum: 2700,
						maximum: 5000,
					},
					defaultTemperature: 3000,
				},
			},
		},
	],
};

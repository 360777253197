import * as React from 'react';

import { firmwareService, firmwareServiceMock } from '../services/firmwareService';
import { featureFlags } from '../services/flags';
import { loginService, loginServiceMock } from '../services/loginService';
import { templateService } from '../services/templateService';
import { CompanyStore } from './companyStore';
import { FirmwareStore } from './firmwareStore';
import { LoginStore } from './loginStore';

// Per service toggle to allow switching over to implemented services once backend is available.
const services = {
	loginService: featureFlags.useMockService ? loginServiceMock : loginService,
	firmwareService: featureFlags.useMockService ? firmwareServiceMock : firmwareService,
	templateService: templateService,
};

const loginStore = new LoginStore({ loginService: services.loginService });
const firmwareStore = new FirmwareStore({
	firmwareService: services.firmwareService,
	loginStore,
	templateService: services.templateService,
});
const companyStore = new CompanyStore({});

export const stores = {
	loginStore,
	firmwareStore,
	companyStore,
};

export const StoreContext = React.createContext(stores);

export const useStores = (): typeof stores => React.useContext(StoreContext);

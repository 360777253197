/**
 * Meta config
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import type * as monaco from 'monaco-editor';
import * as React from 'react';
import { useTheme } from '@emotion/react';
import Editor, { BeforeMount, OnChange, OnMount } from '@monaco-editor/react';
import { observer } from 'mobx-react';

import { useStores } from '../stores';
import { qSeriesSchema } from '../templates';
import { Loading } from './Loading';

// type ChangeEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

export interface ConfigurationEditorRef {
	format: () => void;
	getValue: () => string;
}

interface Props {
	editorRef?: React.RefObject<ConfigurationEditorRef>;
}
export const ConfigurationEditor: React.FunctionComponent<Props> = observer(props => {
	const { editorRef } = props;
	const theme = useTheme();
	const { firmwareStore } = useStores();
	const { firmwareSelection } = firmwareStore;
	const schema = qSeriesSchema;

	const monacoEditorRef = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
	const monacoRef = React.useRef<typeof monaco | null>(null);

	React.useImperativeHandle(editorRef, () => ({
		format: () => {
			monacoEditorRef.current?.getAction('editor.action.formatDocument').run();
		},
		getValue: () => {
			return monacoEditorRef.current?.getValue() ?? '';
		},
	}));

	const handleEditorWillMount: BeforeMount = React.useCallback(
		monaco => {
			monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
				validate: true,
				allowComments: true,
				schemaRequest: 'ignore',
				schemaValidation: 'error',
				schemas: [
					{
						uri: 'http://myserver/foo-schema.json',
						fileMatch: ['*'],
						schema,
					},
				],
			});
		},
		[schema]
	);

	React.useEffect(() => {
		monacoRef.current?.languages.json.jsonDefaults.setDiagnosticsOptions({
			validate: true,
			allowComments: true,
			schemaRequest: 'ignore',
			schemaValidation: 'error',
			schemas: [
				{
					uri: 'http://myserver/foo-schema.json',
					fileMatch: ['*'],
					schema,
				},
			],
		});
	}, [schema]);

	const handleEditorDidMount: OnMount = React.useCallback((editor, monaco) => {
		monacoEditorRef.current = editor;
		monacoRef.current = monaco;
	}, []);

	const handleOnChange: OnChange = React.useCallback(
		_evt => {
			if (_evt != null) {
				firmwareSelection?.workingCopy.setConfig(_evt);
			}
			firmwareSelection?.setValid(false);
		},
		[firmwareSelection]
	);

	if (firmwareSelection == null) return null;
	const { workingCopy } = firmwareSelection;
	const { config } = workingCopy;

	return (
		<Editor
			theme={theme.palette.mode === 'dark' ? 'vs-dark' : 'vs'}
			beforeMount={handleEditorWillMount}
			onMount={handleEditorDidMount}
			height="100%"
			defaultLanguage="json"
			value={config}
			onChange={handleOnChange}
			loading={<Loading />}
		/>
	);
});

/**
 * Login service
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import * as E from 'fp-ts/lib/Either';
import * as TE from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
import StatusCodes from 'http-status-codes';

import { AccountInfo, LoginData } from '@thingos/firmware-configurator-shared';

import { apiService, FetchError } from './apiService';

export type LoginSuccess = {
	accountInfo: AccountInfo;
};
export type LoginFailure = 'NoSuchUser' | 'WrongPassword' | FetchError;
function login(loginData: LoginData): TE.TaskEither<LoginFailure, LoginSuccess> {
	return pipe(
		TE.tryCatch(
			async () =>
				fetch(apiService.api + '/login', {
					method: 'POST',
					credentials: 'include',
					mode: 'cors',
					headers: new Headers({
						'Content-Type': 'application/json',
					}),
					body: JSON.stringify(loginData),
				}),
			() => 'UnknownError' as LoginFailure
		),
		TE.chain(response => {
			if (response.status === StatusCodes.OK) {
				return pipe(
					TE.tryCatch(
						async () => response.json(),
						() => 'Could not get json body of response'
					),
					TE.chain(body =>
						pipe(
							AccountInfo.decode(body),
							E.mapLeft(() => 'Could not decode response'),
							TE.fromEither,
							TE.map(accountInfo => ({ accountInfo }))
						)
					),
					TE.mapLeft(error => {
						console.error(error);
						return 'UnknownError';
					})
				);
			} else {
				if (response.status === StatusCodes.UNAUTHORIZED) {
					return TE.left('NoSuchUser');
				} else if (response.status === StatusCodes.FORBIDDEN) {
					return TE.left('WrongPassword');
				} else {
					return TE.left('ServerError');
				}
			}
		})
	);
}

export type GetAccountInfoFailure = 'Unauthorized' | FetchError;
function getAccountInfo(): TE.TaskEither<GetAccountInfoFailure, AccountInfo> {
	return pipe(
		TE.tryCatch<GetAccountInfoFailure, Response>(
			async () =>
				fetch(apiService.api + '/user', {
					method: 'GET',
					credentials: 'include',
					mode: 'cors',
				}),
			() => 'UnknownError'
		),
		TE.chain(response => {
			if (response.status === StatusCodes.OK) {
				return pipe(
					TE.tryCatch(
						async () => response.json(),
						() => 'Could not get json body of response'
					),
					TE.chain(body =>
						pipe(
							AccountInfo.decode(body),
							E.mapLeft(() => 'Could not decode response'),
							TE.fromEither
						)
					),
					TE.mapLeft(error => {
						console.error(error);
						return 'UnknownError';
					})
				);
			} else {
				if (response.status === StatusCodes.UNAUTHORIZED) {
					return TE.left('Unauthorized');
				} else {
					return TE.left('ServerError');
				}
			}
		})
	);
}

export type LogoutFailure = FetchError;
/**
 * Will redirect to /login on successful logout
 */
function logout(): TE.TaskEither<LogoutFailure, true> {
	return pipe(
		TE.tryCatch(
			async () =>
				fetch(apiService.api + '/logout', {
					method: 'POST',
					credentials: 'include',
					mode: 'cors',
				}),
			() => 'UnknownError' as LogoutFailure
		),
		TE.chain(response => {
			if (response.status === StatusCodes.OK) {
				window.location.href = '/login';
				return TE.right(true);
			} else {
				return TE.left('ServerError');
			}
		})
	);
}

export const loginService = {
	login,
	logout,
	getAccountInfo,
};
export type LoginService = typeof loginService;

export const loginServiceMock: LoginService = {
	login(loginData: LoginData) {
		return pipe(
			TE.tryCatch(
				async () => new Promise(resolve => setTimeout(resolve, Math.random() * 500)),
				() => 'UnknownError' as LoginFailure
			),
			TE.chain(() => {
				if (loginData.email.endsWith('@thingos.io')) {
					if (loginData.email.startsWith('admin')) {
						if (loginData.password === 'admin') {
							return TE.right({
								accountInfo: {
									email: loginData.email,
									name: 'Administrator Test',
									isAdmin: true,
									id: 'c2d0b7d9-a1e5-4518-950a-aac64fd3a4e5',
								},
							});
						} else {
							return TE.left('WrongPassword');
						}
					} else {
						return TE.right({
							accountInfo: {
								email: loginData.email,
								isAdmin: false,
								id: 'c770e50d-cbbd-4391-a770-6fd7f0acd2d1',
							},
						});
					}
				} else {
					return TE.left('NoSuchUser');
				}
			})
		);
	},
	getAccountInfo() {
		return pipe(
			TE.tryCatch(
				async () => new Promise(resolve => setTimeout(resolve, Math.random() * 500)),
				() => 'UnknownError' as GetAccountInfoFailure
			),
			TE.map(() => ({
				email: 'admin@thingos.io',
				name: 'Administrator Test',
				isAdmin: true,
				id: 'c2d0b7d9-a1e5-4518-950a-aac64fd3a4e5',
			}))
		);
	},

	logout() {
		return pipe(
			TE.tryCatch(
				async () => new Promise(resolve => setTimeout(() => resolve(true), Math.random() * 500)),
				() => 'UnknownError' as LogoutFailure
			)
		);
	},
};

export const publicPaths = {
	login: '/login',
	registration: '/accept-invite/:invitation',
	// requestPasswordReset: '/request-password-reset',
	// passwordResetLinkSent: '/password-reset-sent',
	// passwordReset: '/password-reset/:passwordResetToken',
	// termsOfService: '/terms-of-service',
};

export const publicRoutes = Object.values(publicPaths);

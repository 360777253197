/**
 * Meta config
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { Grid, Stack, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Chip } from '@thingos/thingos-components';

import { useStores } from '../stores';

type ChangeEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}
export const MetaConfiguration: React.FunctionComponent<Props> = observer(_props => {
	const { firmwareStore } = useStores();
	const { firmwareSelection } = firmwareStore;

	const onValueChange = React.useCallback(
		(e: ChangeEvent) => firmwareSelection?.workingCopy.setName(e.target.value),
		[firmwareSelection]
	);
	const onChangeTemplate = React.useCallback(
		(e: string) => firmwareSelection?.loadTemplate(e),
		[firmwareSelection]
	);

	if (firmwareSelection == null) return null;

	const { workingCopy, template } = firmwareSelection;
	const { name } = workingCopy;

	return (
		<Grid padding={4} spacing={2} container>
			<Grid item xs={12}>
				<Stack spacing={1}>
					<Typography variant="subtitle2" color="textSecondary">
						General info
					</Typography>
					<TextField fullWidth label="Name" value={name} onChange={onValueChange} />
				</Stack>
			</Grid>
			<Grid item xs={12}>
				<Stack spacing={1}>
					<Typography variant="subtitle2" color="textSecondary">
						Template
					</Typography>
					<Chip.Selection
						value={template}
						onChange={onChangeTemplate}
						options={['MW', 'Mono', 'D2W']}
					/>
				</Stack>
			</Grid>
		</Grid>
	);
});

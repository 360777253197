import type { JSONSchema7 } from 'json-schema';

export const qSeriesSchema: JSONSchema7 = {
	$schema: 'http://json-schema.org/draft-07/schema',
	$id: 'https://mesh-config.thingos.io/schemas/q-series-schema-mw.json',
	type: 'object',
	title: 'Q-series device',
	description: 'Configuration for a Q Series firmware',
	default: {},
	required: ['version', 'type', 'metadata', 'properties', 'modules'],
	properties: {
		version: {
			type: 'string',
			title: 'The version schema',
			description: 'Version of the resulting firmware',
			default: '1.0.0',
		},
		type: {
			type: 'string',
			title: 'Device type',
			description: 'The unique identifier of the device family.',
			enum: [
				'Q4MW',
				'Q16MW',
				'Q36MW',
				'Q49MW',
				'Q64MW',
				'Q100MW',
				'Q4M',
				'Q16M',
				'Q36M',
				'Q49M',
				'Q64M',
				'Q100M',
				'ZEN',
			],
			default: ['Q16MW'],
		},
		metadata: {
			type: 'object',
			title: 'Metadata',
			description: 'Metadata information for the lamp for app integration.',
			required: ['ledCount', 'name'],
			properties: {
				ledCount: {
					type: 'integer',
					title: 'Led count.',
					description: 'Number of LEDs in the lamp',
					default: 36,
					examples: [36],
				},
				name: {
					type: 'string',
					title: 'Device name',
					description: 'The non-localized name of the device',
					default: 'Q16 MW 2700K - 5000K',
					examples: ['Q16 MW 2700K - 5000K'],
				},
			},
			additionalProperties: false,
		},
		properties: {
			type: 'object',
			title: 'Device properties',
			description: 'General properties of the device firmware.',
			default: {},
			required: ['setupButtonActiveHigh', 'statusLedActiveHigh'],
			properties: {
				setupButtonActiveHigh: {
					type: 'boolean',
					title: 'Setup button active high',
					description: 'Set to true if setup button is active high.',
					default: true,
				},
				statusLedActiveHigh: {
					type: 'boolean',
					title: 'Status LED active high',
					description: 'Set to true if status LED is active high.',
					default: true,
				},
			},
			additionalProperties: false,
		},
		modules: {
			type: 'array',
			title: 'Device modules',
			description: 'Module configuration which is loaded by the device.',
			default: {},
			items: {
				oneOf: [
					{ $ref: '#/definitions/Spim' },
					{ $ref: '#/definitions/DigitalOut' },
					{ $ref: '#/definitions/SpimDevice' },
					{ $ref: '#/definitions/Tps92518' },
					{ $ref: '#/definitions/PwmPool' },
					{ $ref: '#/definitions/PwmOut' },
					{ $ref: '#/definitions/AnalogOutSplitRange' },
					{ $ref: '#/definitions/AnalogOutTransferFunction' },
					{ $ref: '#/definitions/TunableWhiteOutSplitWarmCold' },
					{ $ref: '#/definitions/TunableWhiteLight' },
					{ $ref: '#/definitions/FixedTemperatureLight' },
					{ $ref: '#/definitions/AnalogIn' },
					{ $ref: '#/definitions/AnalogOutBinaryOperation' },
					{ $ref: '#/definitions/AnalogOutSplitTwo' },
					{ $ref: '#/definitions/AnalogOutHysteresis' },
					{ $ref: '#/definitions/AnalogInToOut' },
					{ $ref: '#/definitions/NotificationBlinkTunableWhite' },
					{ $ref: '#/definitions/Comp' },
					{ $ref: '#/definitions/PowerFailureDigitalIn' },
					{ $ref: '#/definitions/StatusLed' },
					{ $ref: '#/definitions/Configuration' },
					{ $ref: '#/definitions/AnalogOutPowerManager' },
					{ $ref: '#/definitions/DigitalOutSplitTwo' },
					{ $ref: '#/definitions/DigitalOutBinaryOperation' },
					{ $ref: '#/definitions/AnalogOutRunningAverage' },
				],
			},
			properties: {},
			additionalProperties: false,
		},
	},
	// we can define enums and other types, that we can reference in the schema above
	definitions: {
		GpioPin: {
			type: 'integer',
			minimum: 0,
			maximum: 255,
		},
		FloatRange: {
			type: 'object',
			required: ['minimum', 'maximum'],
			properties: {
				minimum: {
					type: 'number',
				},
				maximum: {
					type: 'number',
				},
			},
		},
		LightnessRange: {
			type: 'object',
			required: ['minimum', 'maximum'],
			properties: {
				minimum: {
					type: 'integer',
					minimum: 0,
					maximum: 65535,
				},
				maximum: {
					type: 'integer',
					minimum: 0,
					maximum: 65535,
				},
			},
		},
		TemperatureRange: {
			type: 'object',
			required: ['minimum', 'maximum'],
			properties: {
				minimum: {
					type: 'integer',
					minimum: 800,
					maximum: 20000,
				},
				maximum: {
					type: 'integer',
					minimum: 800,
					maximum: 20000,
				},
			},
		},
		Function: {
			type: 'array',
			title: 'Function',
			description: 'The function is linearly interpolated between supporting points.',
			default: [],

			additionalItems: true,
			items: {
				type: 'object',
				title: 'Supporting point',
				description: 'A single supporting point of the function.',
				default: {},

				required: ['x', 'y'],
				properties: {
					x: {
						type: 'number',
						title: 'X value',
						description: 'Input value to the function.',
						default: 0,
					},
					y: {
						type: 'number',
						title: 'Y value',
						description: 'Scaled output value of the function.',
						default: 0,
					},
				},
				additionalProperties: false,
			},
		},
		Tps92518Channel: {
			type: 'object',
			title: 'TPS92518 Channel configuration',
			description: 'Analog dimming configuration of one TPS92518 channel.',
			default: {},
			required: ['enabled', 'tOff', 'maxOff', 'peakThreshold'],
			properties: {
				enabled: {
					type: 'boolean',
					title: 'Enabled',
					description: 'Output is enabled on this channel.',
					default: true,
				},
				tOff: {
					type: 'integer',
					title: 'tOff',
					description: 'See TPS92518 data sheet for details.',
					default: 0,
				},
				maxOff: {
					type: 'integer',
					title: 'maxOff',
					description: 'See TPS92518 data sheet for details.',
					default: 0,
				},
				peakThreshold: {
					type: 'integer',
					title: 'Peak threshold',
					description: 'See TPS92518 data sheet for details.',
					default: 0,
				},
			},
			additionalProperties: false,
		},
		BinaryArithmeticOperator: {
			type: 'string',
			title: 'Binary arithmetic operator',
			default: 'Plus',
			enum: ['Plus', 'Minus', 'Multiply', 'Divide', 'Minimum', 'Maximum'],
		},
		BinaryLogicalOperator: {
			type: 'string',
			title: 'Binary logical operator',
			default: 'Plus',
			enum: ['Or', 'And', 'Xor', 'Nor', 'Nand'],
		},
		NotificationType: {
			type: 'string',
			title: 'Notification type',
			default: 'None',
			enum: [
				'None',
				'Running',
				'Unprovisioned',
				'ProvisioningOutput',
				'ProvisioningInput',
				'ProvisionerState',
				'FactoryReset',
				'EnterBootloader',
				'BatteryState',
				'ProvisionerSelect',
				'ProvisionerWait',
				'ProvisionerProgress',
				'ProvisionerFailed',
				'ProvisionerComplete',
				'Acknowledge',
				'Attention',
				'SetupButton',
			],
		},
		NotificationTypeSet: {
			type: 'array',
			title: 'Notification types',
			description: 'List of notification types',
			default: [],
			items: {
				$ref: '#/definitions/NotificationType',
			},
		},
		WarmCold: {
			type: 'object',
			title: 'Warm cold',
			description: 'Tunable white value defined by warm and cold value.',
			required: ['warm', 'cold'],
			properties: {
				warm: {
					type: 'number',
				},
				cold: {
					type: 'number',
				},
			},
		},
		WarmColdRange: {
			type: 'object',
			title: 'Warm cold range',
			description: 'Range of warm and cold tunable white values.',
			required: ['minimum', 'maximum'],
			properties: {
				minimum: {
					$ref: '#/definitions/WarmCold',
				},
				maximum: {
					$ref: '#/definitions/WarmCold',
				},
			},
		},
		TunableWhite: {
			type: 'object',
			title: 'Tunable white',
			description: 'Tunable white value defined by lightness and temperature value.',
			required: ['lightness', 'temperature'],
			properties: {
				lightness: {
					type: 'number',
				},
				temperature: {
					type: 'number',
				},
			},
		},
		TunableWhiteRange: {
			type: 'object',
			title: 'Tunable white range',
			description: 'Range of tunable white values.',
			required: ['minimum', 'maximum'],
			properties: {
				minimum: {
					$ref: '#/definitions/TunableWhite',
				},
				maximum: {
					$ref: '#/definitions/TunableWhite',
				},
			},
		},
		Spim: {
			type: 'object',
			title: 'SPIM module',
			description: 'SPI master module.',
			required: ['Spim'],
			properties: {
				Spim: {
					type: 'object',
					title: 'SPIM module',
					description: 'SPI master module.',
					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},
							required: ['sck', 'mosi', 'miso', 'cs'],
							properties: {
								sck: {
									$ref: '#/definitions/GpioPin',
									title: 'Clock pin',
									description: 'GPIO pin for the clock line.',
									default: 1,
								},
								mosi: {
									$ref: '#/definitions/GpioPin',
									title: 'MOSI pin',
									description: 'GPIO pin for the MOSI line.',
									default: 2,
								},
								miso: {
									$ref: '#/definitions/GpioPin',
									title: 'MISO pin',
									description: 'GPIO pin for the MISO line.',
									default: 3,
								},
								cs: {
									$ref: '#/definitions/GpioPin',
									title: 'Chip select pin',
									description: 'GPIO pin for the chip select line.',
									default: 255,
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},
							required: ['driverIndex', 'mode'],
							properties: {
								driverIndex: {
									type: 'integer',
									title: 'Driver index',
									description: 'The SPIM peripheral driver index used for this module.',
									default: 2,
									const: 2,
								},
								mode: {
									type: 'integer',
									title: 'SPIM mode',
									description: 'The SPIM mode of operation, please refer to the nRF52 datasheet.',
									default: 0,
									const: 0,
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		DigitalOut: {
			type: 'object',
			title: 'Digital out module',
			description: 'Digital out functionality provided on a GPIO pin.',
			required: ['DigitalOut'],
			properties: {
				DigitalOut: {
					type: 'object',
					title: 'Digital out module',
					description: 'Digital out functionality provided on a GPIO pin.',
					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},
							required: ['pin'],
							properties: {
								pin: {
									$ref: '#/definitions/GpioPin',
									title: 'Digital out pin',
									description: 'GPIO pin for the digital output.',
									default: 4,
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['value'],
							properties: {
								value: {
									type: 'boolean',
									title: 'Initial value',
									description: 'Value of the digital output before it is set to a value.',
									default: null,
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		SpimDevice: {
			type: 'object',
			title: 'Spim device module',
			description: 'A spim device which uses a digital out for chip selection.',
			required: ['SpimDevice'],
			properties: {
				SpimDevice: {
					type: 'object',
					title: 'Spim device module',
					description: 'A spim device which uses a digital out for chip selection.',
					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},
							required: ['spim', 'cs'],
							properties: {
								spim: {
									type: 'string',
									title: 'SPI master',
									description: 'SPI master used by this device.',
									default: 'spim.data',
									examples: ['spim.data'],
								},
								cs: {
									type: 'string',
									title: 'Chip select',
									description: 'Chip select output used by this device.',
									default: 'cs.out',
									examples: ['cs.out'],
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},
							required: [],
							properties: {},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		Tps92518: {
			type: 'object',
			title: 'TPS92518 module',
			description: 'Driver module for the TPS92518 LED driver.',
			required: ['Tps92518'],
			properties: {
				Tps92518: {
					type: 'object',
					title: 'TPS92518 module',
					description: 'Driver module for the TPS92518 LED driver.',
					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},
							required: ['spim'],
							properties: {
								spim: {
									type: 'string',
									title: 'SPI master',
									description: 'SPI master used by the TPS92518 module.',
									default: 'spimDevice.data',
									examples: ['spimDevice.data'],
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},
							required: ['channel1Config', 'channel2Config'],
							properties: {
								channel1Config: {
									$ref: '#/definitions/Tps92518Channel',
									title: 'Channel 1 configuration',
									description: 'Analog dimming configuration of TPS channel 1.',
								},
								channel2Config: {
									$ref: '#/definitions/Tps92518Channel',
									title: 'Channel 2 configuration',
									description: 'Analog dimming configuration of TPS channel 2.',
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		PwmPool: {
			type: 'object',
			title: 'PWM pool',
			description: 'Multiple PWM driver instances merged to a pool.',
			default: {},
			required: ['PwmPool'],
			properties: {
				PwmPool: {
					type: 'object',
					title: 'PWM pool',
					description: 'Multiple PWM driver instances merged to a pool.',
					default: {},
					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},
							required: [],
							properties: {},
							additionalProperties: false,
						},
						properties: {
							driverIndexes: {
								type: 'array',
								title: 'Driver indexes',
								description: 'nRF52 driver indexes used for this pool.',
								default: [0],

								additionalItems: true,
								items: {
									type: 'integer',
									title: 'Driver index',
									description: 'Index of a PWM driver.',
									default: 0,
								},
							},
							frequency: {
								type: 'integer',
								title: 'Pwm frequency',
								description: 'The frequency of the PWM modulation in Hz [100000-16000000]',
								minimum: 100000,
								maximum: 16000000,
								default: 8000000,
							},
							top: {
								type: 'integer',
								title: 'PWM top',
								description: 'Value the PWM driver counts to before starting over.',
								default: 16393,
							},
							count: {
								type: 'string',
								title: 'PWM count',
								description: 'Direction for counting, either Up or UpDown',
								default: 'UpDown',
								enum: ['Up', 'UpDown'],
							},
						} as any,
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		PwmOut: {
			type: 'object',
			title: 'PWM out module',
			description: 'PWM out functionality provided on a GPIO pin.',
			default: {},
			required: ['PwmOut'],
			properties: {
				PwmOut: {
					type: 'object',
					title: 'PWM out module',
					description: 'PWM out functionality provided on a GPIO pin.',
					default: {},
					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},
							required: ['pool', 'pin'],
							properties: {
								pool: {
									type: 'string',
									title: 'PWM pool',
									description: 'PWM pool to use for this output.',
									default: 'pwmPool.pool',
								},
								pin: {
									$ref: '#/definitions/GpioPin',
									title: 'PWM out pin',
									description: 'GPIO pin for the PWM output.',
									default: 5,
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},
							required: ['inverted', 'minDutyCycle'],
							properties: {
								inverted: {
									type: 'boolean',
									title: 'Inverted',
									description: 'Invert PWM output.',
									default: false,
								},
								minDutyCycle: {
									type: 'number',
									title: 'Minimum duty cycle',
									description: 'Minimum value when duty cycle is not zero.',
									default: 0,
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		AnalogOutSplitRange: {
			type: 'object',
			title: 'Analog output split range',
			description:
				'Split analog output in two ranges. If value is above split, lower will be 100%. If value is below split, upper will be split and lower will be value normalized to [0, split].',
			default: {},
			required: ['AnalogOutSplitRange'],
			properties: {
				AnalogOutSplitRange: {
					type: 'object',
					title: 'Analog output split range',
					description:
						'Split analog output in two ranges. If value is above split, lower will be 100%. If value is below split, upper will be split and lower will be value normalized to [0, split].',
					default: {},
					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},
							required: ['upper', 'lower'],
							properties: {
								upper: {
									title: 'Upper analog output',
									description: 'Analog output used for the upper range.',
									type: 'string',
								},
								lower: {
									title: 'Lower analog output',
									description: 'Analog output used for the lower range.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},
							required: ['split'],
							properties: {
								split: {
									type: 'number',
									title: 'Split value',
									description: 'Value at which the split happens.',
									default: 0.0,
									minimum: 0,
									maximum: 1,
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		AnalogOutTransferFunction: {
			type: 'object',
			title: 'Analog out transfer function module',
			description: 'Apply a transfer function to the analog output.',
			default: {},

			required: ['AnalogOutTransferFunction'],
			properties: {
				AnalogOutTransferFunction: {
					type: 'object',
					title: 'Analog out transfer function module',
					description: 'Apply a transfer function to the analog output.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['out'],
							properties: {
								out: {
									title: 'Analog output',
									description: 'Analog output after applying transfer function.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['function'],
							properties: {
								function: {
									type: 'array',
									title: 'Transfer function',
									description:
										'The transfer function is linearly interpolated between supporting points.',
									default: [],

									additionalItems: true,
									items: {
										type: 'object',
										title: 'Supporting point',
										description: 'A single supporting point of the function.',
										default: {},

										required: ['x', 'y'],
										properties: {
											x: {
												type: 'number',
												title: 'X value',
												description: 'Input value to the function.',
												default: 0,
											},
											y: {
												type: 'number',
												title: 'Y value',
												description: 'Scaled output value of the function.',
												default: 0,
											},
										},
										additionalProperties: false,
									},
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		TunableWhiteOutSplitWarmCold: {
			type: 'object',
			title: 'Tunable white out split warm cold module',
			description: 'Convert lightness and temperature values into warm and cold white channels.',
			default: {},

			required: ['TunableWhiteOutSplitWarmCold'],
			properties: {
				TunableWhiteOutSplitWarmCold: {
					type: 'object',
					title: 'Tunable white out split warm cold module',
					description:
						'Convert lightness and temperature values into warm and cold white channels.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['warm', 'cold'],
							properties: {
								warm: {
									title: 'Warm white analog output',
									description: 'Analog output to write warm white values to.',
									type: 'string',
								},
								cold: {
									title: 'Cold white analog output',
									description: 'Analog output to write cold white values to.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['temperatureCurve'],
							properties: {
								temperatureCurve: {
									$ref: '#/definitions/Function',
									title: 'Temperature curve.',
									description:
										'Mapping from color temperature to cold white ratio (0.0 -> 100% ww, 1.0 -> 100% cw).',
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		TunableWhiteLight: {
			type: 'object',
			title: 'Tunable white light module',
			description: 'A color tunable white light.',
			required: ['TunableWhiteLight'],
			properties: {
				TunableWhiteLight: {
					type: 'object',
					title: 'Tunable white light module',
					description: 'A color tunable white light.',
					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['out'],
							properties: {
								out: {
									title: 'Tunable white output',
									description: 'Tunable white output value.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: [
								'defaultPowerUpBehavior',
								'lightnessRange',
								'defaultLightness',
								'temperatureRange',
								'defaultTemperature',
								'temperatureLimits',
							],
							properties: {
								defaultPowerUpBehavior: {
									type: 'string',
									title: 'Default power up behavior',
									description: 'Default behavior when power is turned on.',
									default: 'Restore',
									enum: ['Off', 'Default', 'Restore'],
								},
								lightnessRange: {
									$ref: '#/definitions/LightnessRange',
									title: 'Lightness range.',
									description: 'Minimum and maximum lightness of the light.',
								},
								defaultLightness: {
									type: 'integer',
									title: 'Default temperature',
									description: 'Default color temperature after factory reset.',
									default: 65535,
									maximum: 65535,
									minimum: 0,
								},
								temperatureRange: {
									$ref: '#/definitions/TemperatureRange',
									title: 'Temperature range.',
									description: 'Minimum and maximum color temperature of the light.',
								},
								defaultTemperature: {
									type: 'integer',
									title: 'Default temperature',
									description: 'Default color temperature after factory reset.',
									default: 3000,
									minimum: 800,
									maximum: 20000,
								},
								temperatureLimits: {
									$ref: '#/definitions/TemperatureRange',
									title: 'Temperature limits.',
									description: 'Minimum and maximum color temperature of the attached LEDs.',
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		FixedTemperatureLight: {
			type: 'object',
			title: 'Tunable white light module',
			description: 'A color tunable white light.',
			required: ['FixedTemperatureLight'],
			properties: {
				FixedTemperatureLight: {
					type: 'object',
					title: 'Tunable white light module',
					description: 'A color tunable white light.',
					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['warm', 'cold'],
							properties: {
								warm: {
									title: 'Warm white analog output',
									description: 'Analog output value for the warm white color component.',
									type: 'string',
								},
								cold: {
									title: 'Cold white analog output',
									description: 'Analog output value for the cold white color component.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['temperatureRange', 'temperature', 'temperatureCurve'],
							properties: {
								defaultPowerUpBehavior: {
									type: 'string',
									title: 'Default power up behavior',
									description: 'Default behavior when power is turned on.',
									default: 'Restore',
									enum: ['Off', 'Default', 'Restore'],
								},
								lightnessRange: {
									$ref: '#/definitions/LightnessRange',
									title: 'Lightness range.',
									description: 'Minimum and maximum lightness of the light.',
								},
								defaultLightness: {
									type: 'integer',
									title: 'Default temperature',
									description: 'Default color temperature after factory reset.',
									default: 65535,
									maximum: 65535,
									minimum: 0,
								},
								temperatureRange: {
									$ref: '#/definitions/TemperatureRange',
									title: 'Temperature range.',
									description: 'Minimum and maximum color temperature of the light.',
								},
								temperature: {
									type: 'integer',
									title: 'Temperature',
									description: 'Fixed color temperature.',
									default: 3000,
									minimum: 800,
									maximum: 20000,
								},
								temperatureCurve: {
									$ref: '#/definitions/Function',
									title: 'Temperature curve.',
									description:
										'Mapping from color temperature to cold white ratio (0.0 -> 100% ww, 1.0 -> 100% cw).',
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		AnalogIn: {
			type: 'object',
			title: 'Analog in module',
			description: 'Periodically read from an analog input.',
			default: {},

			required: ['AnalogIn'],
			properties: {
				AnalogIn: {
					type: 'object',
					title: 'Analog in module',
					description: 'Periodically read from an analog input.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['pin'],
							properties: {
								pin: {
									$ref: '#/definitions/GpioPin',
									title: 'Analog input pin',
									description: 'Input pin to sample.',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['samplingInterval'],
							properties: {
								samplingInterval: {
									title: 'Sampling interval',
									description: 'Interval in milliseconds for input sampling.',
									default: [],
									type: 'integer',
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		AnalogOutBinaryOperation: {
			type: 'object',
			title: 'Analog out binary operation module',
			description: 'Merges two analog outputs by applying a binary operation.',
			default: {},

			required: ['AnalogOutBinaryOperation'],
			properties: {
				AnalogOutBinaryOperation: {
					type: 'object',
					title: 'Analog out binary operation module',
					description: 'Merges two analog outputs by applying a binary operation.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['out'],
							properties: {
								out: {
									title: 'Result output',
									description: 'Analog output to write the result to.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['op'],
							properties: {
								op: {
									title: 'Operator',
									description: 'Binary arithmetic operator to apply.',
									$ref: '#/definitions/BinaryArithmeticOperator',
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		AnalogOutSplitTwo: {
			type: 'object',
			title: 'Analog out split two module',
			description: 'Splits one analog output into two analog outputs.',
			default: {},

			required: ['AnalogOutSplitTwo'],
			properties: {
				AnalogOutSplitTwo: {
					type: 'object',
					title: 'Analog out split two module',
					description: 'Splits one analog output into two analog outputs.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['first', 'second'],
							properties: {
								first: {
									title: 'First output',
									description: 'First output to write the input data.',
									type: 'string',
								},
								second: {
									title: 'Second output',
									description: 'Second output to write the input data.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: [],
							properties: {},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		AnalogOutHysteresis: {
			type: 'object',
			title: 'Analog out hysteresis module',
			description: 'Applies a hysteresis to the input.',
			default: {},

			required: ['AnalogOutHysteresis'],
			properties: {
				AnalogOutHysteresis: {
					type: 'object',
					title: 'Analog out hysteresis module',
					description: 'Applies a hysteresis to the input.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['out'],
							properties: {
								out: {
									title: 'Result output',
									description: 'Analog output to write the result to.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['range', 'inverted'],
							properties: {
								range: {
									title: 'Hysteresis range',
									description: 'Turn on when maximum is reached, turn off when minimum is reached.',
									$ref: '#/definitions/FloatRange',
								},
								inverted: {
									title: 'Invert output',
									description:
										'If set, turn off when maximum is reached, turn on when minimum is reached.',
									type: 'boolean',
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		AnalogInToOut: {
			type: 'object',
			title: 'Analog in to out module',
			description: 'Connects an analog input directly to an analog output.',
			default: {},

			required: ['AnalogInToOut'],
			properties: {
				AnalogInToOut: {
					type: 'object',
					title: 'Analog in to out module',
					description: 'Connects an analog input directly to an analog output.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['in', 'out'],
							properties: {
								in: {
									title: 'Analog input',
									description: 'Analog input to read from.',
									type: 'string',
								},
								out: {
									title: 'Analog output',
									description: 'Analog output to write to.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['enabled'],
							properties: {
								enabled: {
									title: 'Enabled',
									description: 'If set output is connected to input',
									type: 'boolean',
									default: true,
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		NotificationBlinkTunableWhite: {
			type: 'object',
			title: 'Notification blink tunable white module',
			description: 'Let tunable white output blink on certain notifications.',
			default: {},

			required: ['NotificationBlinkTunableWhite'],
			properties: {
				NotificationBlinkTunableWhite: {
					type: 'object',
					title: 'Notification blink tunable white module',
					description: 'Let tunable white output blink on certain notifications.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['out'],
							properties: {
								out: {
									title: 'Tunable white output',
									description: 'Tunable white output to write values to.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['types', 'range'],
							properties: {
								types: {
									title: 'Notification types',
									description: 'List of notification types which trigger blinking',
									$ref: '#/definitions/NotificationTypeSet',
									default: [],
								},
								range: {
									title: 'Blink range',
									description: 'Minimum and maximum light values during blinking.',
									$ref: '#/definitions/TunableWhiteRange',
									default: [],
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		NotificationBlinkMonochrome: {
			type: 'object',
			title: 'Notification blink monochrome module',
			description: 'Let monochrome output blink on certain notifications.',
			default: {},

			required: ['NotificationBlinkMonochrome'],
			properties: {
				NotificationBlinkMonochrome: {
					type: 'object',
					title: 'Notification blink monochrome module',
					description: 'Let monochrome output blink on certain notifications.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['out'],
							properties: {
								out: {
									title: 'Analog output',
									description: 'Analog output to write blink values to.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['types', 'range'],
							properties: {
								types: {
									title: 'Notification types',
									description: 'List of notification types which trigger blinking',
									$ref: '#/definitions/NotificationTypeSet',
									default: [],
								},
								range: {
									title: 'Blink range',
									description: 'Minimum and maximum light values during blinking.',
									$ref: '#/definitions/FloatRange',
									default: [],
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		Comp: {
			type: 'object',
			title: 'Comparator module',
			description: 'Trigger digital output on analog input thresholds.',
			default: {},

			required: ['Comp'],
			properties: {
				Comp: {
					type: 'object',
					title: 'Comparator module',
					description: 'Trigger digital output on analog input thresholds.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['pin'],
							properties: {
								pin: {
									$ref: '#/definitions/GpioPin',
									title: 'Analog input pin',
									description: 'Input pin to sample.',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['threshold'],
							properties: {
								threshold: {
									title: 'Comparator hysteresis',
									description: 'Turn on when maximum is reached, turn off when minimum is reached.',
									$ref: '#/definitions/FloatRange',
									default: [],
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		PowerFailureDigitalIn: {
			type: 'object',
			title: 'Power failure digital in module',
			description: 'Trigger power failure when digital in goes low.',
			default: {},

			required: ['PowerFailureDigitalIn'],
			properties: {
				PowerFailureDigitalIn: {
					type: 'object',
					title: 'Power failure digital in module',
					description: 'Trigger power failure when digital in goes low.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['in'],
							properties: {
								in: {
									title: 'Digital input',
									description: 'Digital input which indicates power failure.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: [],
							properties: {},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		StatusLed: {
			type: 'object',
			title: 'Status LED module',
			description: 'Status LED configuration module.',
			default: {},

			required: ['StatusLed'],
			properties: {
				StatusLed: {
					type: 'object',
					title: 'Status LED module',
					description: 'Status LED configuration module.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},
							required: [],
							properties: {},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['on'],
							properties: {
								on: {
									title: 'Status LED on',
									description: 'Status LED is on during normal operation.',
									default: true,
									type: 'boolean',
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		Configuration: {
			type: 'object',
			title: 'Configuration module',
			description: 'Used to configure other modules at runtime.',
			default: {},

			required: ['Configuration'],
			properties: {
				Configuration: {
					type: 'object',
					title: 'Configuration module',
					description: 'Used to configure other modules at runtime.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},
							required: [],
							properties: {},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},
							required: [],
							properties: {},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		AnalogOutPowerManager: {
			type: 'object',
			title: 'Analog output power manager',
			description: 'Turns off a digital output when analog output reaches zero or less.',
			default: {},
			required: ['AnalogOutPowerManager'],
			properties: {
				AnalogOutPowerManager: {
					type: 'object',
					title: 'Analog output power manager',
					description: 'Turns off a digital output when analog output reaches zero or less.',
					default: {},
					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},
							required: ['out', 'pwr'],
							properties: {
								out: {
									title: 'Analog output',
									description: 'Analog output to pass through.',
									type: 'string',
								},
								pwr: {
									title: 'Power output',
									description: 'Digital output for controlling external power.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},
							required: ['powerSaveDelayMs', 'powerUpDelayMs'],
							properties: {
								powerSaveDelayMs: {
									type: 'integer',
									title: 'Power save delay.',
									description:
										'Delay in milliseconds until pwr turns off after analog ouput reaches zero or less.',
									default: 2000,
								},
								powerUpDelayMs: {
									type: 'integer',
									title: 'Power up delay.',
									description:
										'Delay in milliseconds until analog output is enabled after power is turned on when analog value rises above zero.',
									default: 0,
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		DigitalOutBinaryOperation: {
			type: 'object',
			title: 'Digital out binary operation module',
			description: 'Merges two digital outputs by applying a binary operation.',
			default: {},

			required: ['DigitalOutBinaryOperation'],
			properties: {
				DigitalOutBinaryOperation: {
					type: 'object',
					title: 'Digital out binary operation module',
					description: 'Merges two digital outputs by applying a binary operation.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['out'],
							properties: {
								out: {
									title: 'Result output',
									description: 'Digital output to write the result to.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['op'],
							properties: {
								op: {
									title: 'Operator',
									description: 'Binary logical operator to apply.',
									$ref: '#/definitions/BinaryLogicalOperator',
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		DigitalOutSplitTwo: {
			type: 'object',
			title: 'Digital out split two module',
			description: 'Splits one digital output into two digital outputs.',
			default: {},

			required: ['DigitalOutSplitTwo'],
			properties: {
				DigitalOutSplitTwo: {
					type: 'object',
					title: 'Digital out split two module',
					description: 'Splits one digital output into two digital outputs.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['first', 'second'],
							properties: {
								first: {
									title: 'First output',
									description: 'First output to write the input data.',
									type: 'string',
								},
								second: {
									title: 'Second output',
									description: 'Second output to write the input data.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: [],
							properties: {},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		AnalogOutRunningAverage: {
			type: 'object',
			title: 'Analog out running average module',
			description: 'Applies a running average on the analog value.',
			default: {},

			required: ['AnalogOutRunningAverage'],
			properties: {
				AnalogOutRunningAverage: {
					type: 'object',
					title: 'Analog out running average module',
					description: 'Applies a running average on the analog value.',
					default: {},

					required: ['name', 'uses', 'properties'],
					properties: {
						name: {
							title: 'Module name',
							description: 'Used for referencing this module in other modules.',
							type: 'string',
						},
						uses: {
							type: 'object',
							title: 'Module uses',
							description: 'Capabilities used by this module.',
							default: {},

							required: ['out'],
							properties: {
								out: {
									title: 'Result output',
									description: 'Analog output to write the result to.',
									type: 'string',
								},
							},
							additionalProperties: false,
						},
						properties: {
							type: 'object',
							title: 'Module properties',
							description: 'Properties to configure this module.',
							default: {},

							required: ['fraction'],
							properties: {
								fraction: {
									title: 'Fraction',
									description: 'Influence of new samples on the output value.',
									type: 'number',
									minimum: 0.0,
									maximum: 1.0,
									default: 0.1,
								},
							},
							additionalProperties: false,
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
	},
	additionalProperties: false,
};

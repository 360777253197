/**
 * Meta config
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import * as React from 'react';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react';

import { HStack, VStack } from '@thingos/thingos-components';

import { useStores } from '../stores';
import { ConfigurationEditor } from './ConfigurationEditor';
import { ConfigurationGraph } from './ConfigurationGraph';

// type ChangeEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

export interface ConfigurationEditorRef {
	format: () => void;
	getValue: () => string;
}

interface Props {
	editorRef?: React.RefObject<ConfigurationEditorRef>;
}
export const ConfigurationEditorManager: React.FC<Props> = observer(props => {
	const { editorRef } = props;
	const { firmwareStore } = useStores();
	const firmwareSelection = firmwareStore.firmwareSelection;
	const isGraphMode = firmwareSelection?.isGraphMode;
	const onClickToggleGraphMode = firmwareSelection?.onClickToggleGraphMode;

	const onClickFormat = React.useCallback(() => {
		editorRef?.current?.format();
	}, [editorRef]);

	return (
		<VStack flex={1}>
			<HStack justifyContent="flex-end" spacing={8}>
				<IconButton onClick={onClickToggleGraphMode} size="small">
					<AccountTreeIcon />
				</IconButton>
				<IconButton onClick={onClickFormat} size="small">
					<DataObjectIcon />
				</IconButton>
			</HStack>
			{isGraphMode ? <ConfigurationGraph /> : <ConfigurationEditor editorRef={editorRef} />}
		</VStack>
	);
});
